import React from 'react';
import PropTypes from 'prop-types';

import ErrorArticlesContainer from './ErrorArticlesContainer';
import ErrorArticlesHeading from './ErrorArticlesHeading';

import styles from '../../Main/Articles/articles.module.css';

const ErrorArticlesFinal = ({ wordList, lang }) => {
  const answers = wordList.map((item) => {
    const wordId = item.id;
    const fullAnswer = `${item['answer']} ${item.word}`;

    return (
      <li key={wordId}>
        {fullAnswer}
      </li>
    )
  });

  return (
    <ErrorArticlesContainer>
      <ErrorArticlesHeading
        lang={lang}
        hedingType="final"
      />

      <ul className={styles.finalBox}>
        {answers}
      </ul>
    </ErrorArticlesContainer>
  );
};

ErrorArticlesFinal.propTypes = {
  wordList: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
};

export default ErrorArticlesFinal;
