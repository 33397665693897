import React, { useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LearningContext } from '../../Context';

import FormInput from '../../Admin/FormComponents/FormInput';

import styles from './user.module.css';

const UserLogin = () => {
  const initialFormState = {
    user: '',
    password: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const navigate = useNavigate();
  const { actions } = useContext(LearningContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  // const isValid = () => {
  //   if (
  //     formState.user === ''
  //     || formState.email === ''
  //     || formState.password === ''
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('user', formState.user);
    formData.append('password', formState.password);

    fetch('https://deutscherphoenix.com/api/userLogin.php', {
      method: 'POST',
      body: formData,
    })
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          actions.setUserLogin(true);

          // Set session storage
          localStorage.setItem('isUserLoggedIn', true);
          localStorage.setItem('jwt', responseData.data.jwt);
          navigate('/profile');
        } else {
          // navigate('/');
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
      });
  };


  return ( 
    <main>
      <div className={styles.formWrap}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h3 className={styles.header}>Please Login</h3>

          <FormInput
            label="User"
            name="user"
            value={formState.user}
            handleChange={handleChange}
          />

          <FormInput
            label="Password"
            name="password"
            value={formState.password}
            handleChange={handleChange}
            type="password"
          />

          <button type="submit" className="form__button">
            Login
          </button>
        </form>
      </div>
    </main>
   );
}
 
export default UserLogin;
