import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import WordBoardButton from './WordBoardButton';
import FinalMessage from '../FinalMessage';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';

import './words.css';

import styles from './wordsActivity.module.css';

const WordsActivity = () => {
  const { lang, languageName, categories, actions } = useContext(LearningContext);
  const [isFinalMessageVisible, setIsFinalMessageVisible] = useState(false);
  const { pos, unit, category } = useParams();
  const wordsPos = pos.charAt(0).toUpperCase() + pos.slice(1);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const [words, setWords] = useState([]);
  const [firstClickedId, setFirstClickedId] = useState(undefined);
  const [firstClickedMatchId, setFirstClickedMatchId] = useState(undefined);

  const [totalWords, setTotalWords] = useState(0);
  const [numWordsCorrect, setNumWordsCorrect] = useState(0);
  const [finalScore, setFinalScore] = useState(90);
  let pageContent = '';

  // On mount
  useEffect(() => {
    const wordList = (list) => {
      setWords(list);
      setTotalWords(list.length / 2);
      // Add 2 to correct to cover accidental taps
      setNumWordsCorrect((list.length / 2) + 2)
      setIsLoading(false);
    };

    fetch(
      `https://deutscherphoenix.com/api/words?lang=${lang}&pos=${pos}&unit=${unit}&cat=${category}`
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          wordList(responseData.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchError(true);
        // console.log('Error fetching and parsing data', error);
      });
  }, [lang, pos, unit, category]);

  // Set document head information
  useEffect(() => {
    if (categories[lang] !== undefined) {
      const { setDocumentHead } = actions;

      const headObj = {
        title: `${languageName} ${wordsPos} | Word match exercises`,
        desc: `Practice matching ${languageName} ${wordsPos}s and improve your vocabulary with word match exercises. Match ${languageName} words with English translations.`,
      };
      setDocumentHead(headObj);
    }
  }, [actions, categories, category, lang, languageName, wordsPos]);

  // 
  const toggleClicked = (idToToggle, id, matchId) => {
    setFirstClickedId(id);
    setFirstClickedMatchId(matchId);

    const tempWords = words.map((word) => {
      if (idToToggle === word.id) {
        return {
          ...word,
          isClicked: !word.isClicked,
          isClickCorrect: true,
        };
      }
      return {
        ...word,
        isClickCorrect: true,
      };
    });

    setWords(tempWords);
  };

  // 
  const isFinalMatch = () => {
    const wordsCnt = words.length;
    let matchedCnt = 2;

    words.forEach((word) => {
      if (word.isMatched === true) {
        matchedCnt += 1;
      }
    });

    if (matchedCnt >= wordsCnt) {
      return true;
    }

    return false;
  };

  // Check for word match
  const checkForMatch = (id, matchId) => {

    if (matchId === firstClickedMatchId) {
      setFirstClickedId(undefined);
      setFirstClickedMatchId(undefined);

      const tempWords = words.map((word) => {
        if (matchId === word.matchId) {
          return {
            ...word,
            isClicked: false,
            isMatched: true,
            isClickCorrect: true,
          };
        }
        return {
          ...word,
          isClickCorrect: true,
        };
      });

      setWords(tempWords);

      if (isFinalMatch()) {
        // Calculate and set final score
        const score = Math.round((numWordsCorrect / totalWords) * 100);
        setFinalScore(score);

        setIsFinalMessageVisible(true);
      }
    } else {
      // Subtract 1 from totalWordsCorrect
      let tempNumWordsCorrect = numWordsCorrect - 1;

      setNumWordsCorrect(tempNumWordsCorrect);

      const tempWords = words.map((word) => {
        if (id === word.id) {
          return {
            ...word,
            isClickCorrect: false,
          };
        }
        return {
          ...word,
          isClickCorrect: true,
        };
      });

      setWords(tempWords);
    }
  };

  // Handle a click on a word
  const handleWordClick = (obj) => {
    const { id, matchId } = obj;

    if (!obj.isMatched) {
      if (firstClickedId === undefined) {
        toggleClicked(id, id, matchId);
      } else if (firstClickedId === id) {
        toggleClicked(id, undefined, undefined);
      } else if (firstClickedId !== id) {
        checkForMatch(id, matchId);
      }
    }
  };

  // Get list of words
  const listOfWords = words.map((word, index) => (
    <li className={styles.wordsItem} key={index}>
      <WordBoardButton word={word} handleWordClick={handleWordClick} />
    </li>
  ));

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <>
        <div className={styles.wrap}>
          <div className={styles.words}>
            <ul className={styles.wordsWrap}>{listOfWords}</ul>
          </div>
        </div>

        <div></div>

        {isFinalMessageVisible && (
          <FinalMessage
            type="words"
            lang={lang}
            finalScore={finalScore}
            topicId={category}
            pos={pos}
          />
        )}
      </>
    )
  }

  return (
    <ActivityWrap>
      <ActivityHeader
        heading={`Match the ${wordsPos}s`}
        page="words"
        lang={lang}
      />

      {pageContent}
    </ActivityWrap>
  );
};

export default WordsActivity;
