import React from 'react';
import PropTypes from 'prop-types';

import styles from './formTextarea.module.css';

const FormTextarea = ({
  label, name, value, handleChange, type,
}) => {
  const textareaClass = type === 'large' ? styles.textareaLarge : styles.textarea;

  return (
    <label className={styles.label} htmlFor={name}>
      {label}
      <textarea
        id={name}
        name={name}
        className={textareaClass}
        value={value}
        onChange={handleChange}
      />
    </label>
  );
};

FormTextarea.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
};

export default FormTextarea;
