import React, { Component } from 'react';
import { LearningContext } from '../../Context';

import SearchResults from './SearchResults';
import SearchResultsSimple from './SearchResultsSimple';

import svgSearch from './search.svg';
import styles from './search.module.css';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonData: {},
      modifiedData: [],
      itemSearchType: '',
      itemRange: '',
      extraOptions: [],
    };
  }

  handleType = (e) => {
    const value = this.context;
    const { lang, categories } = value;
    const eValue = e.target.value;
    const catNames = {
      adjectives: 'adjective',
      blanks: 'grammarTopicsBlanks',
      categories: 'category',
      nouns: 'noun',
      phrases: 'phrase',
      sentences: 'grammarTopicsSentences',
      verbs: 'verb',
    };

    this.setState({
      itemSearchType: eValue,
      itemRange: '',
      extraOptions: categories[lang][catNames[eValue]],
    });
  };

  handleRange = (e) => this.setState({ itemRange: e.target.value });

  handleSearchClick = () => {
    const { itemSearchType, itemRange } = this.state;
    const { lang, categories } = this.context;
    const verbCats = categories[lang].verb;
    const categoryCats = categories[lang].category;
    let fetchUrl = 'https://deutscherphoenix.com/api/';

    if (itemSearchType === '' || itemRange === '') {
      return;
    }

    if (itemSearchType !== 'categories') {
      // Value will be a number for pos & exercies category ids and a string for other search types
      const value = Number.parseInt(itemRange, 10);
      if (Number.isInteger(value) && itemSearchType === 'verbs') {
        const tempVerbRange = verbCats.filter((obj) => obj.id === itemRange);
        fetchUrl += `${itemSearchType}?lang=${lang}&${tempVerbRange[0].name}=yes`;
      } else if (Number.isInteger(value) && itemSearchType === 'phrases') {

        const filterOn = Number.isInteger(value) ? 'fKey' : 'range';
        fetchUrl += `${itemSearchType}?lang=${lang}&topic=${itemRange}`;

      } else if ((itemSearchType === 'blanks') || (itemSearchType === 'sentences')) {
        const filterOn = Number.isInteger(value) ? 'fKey' : 'range';
        fetchUrl += `${itemSearchType}?lang=${lang}&${filterOn}=${itemRange}`;
      } else if (Number.isInteger(value)) {
        fetchUrl += `${itemSearchType}?lang=${lang}&cat=${itemRange}`;
      } else {
        fetchUrl += `${itemSearchType}?lang=${lang}&range=${itemRange}`;
      }
    } else if (itemSearchType === 'categories') {
      const tempCatRange = categoryCats.filter((obj) => obj.id === itemRange);
      fetchUrl += `${itemSearchType}?lang=${lang}&type=${tempCatRange[0].name}`;
    }

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseData) => {

        if (responseData.status === 'success') {
          this.setState({
            jsonData: { status: 'sucess' },
            modifiedData: responseData.data,
          });
        } else if (responseData.status === 'fail') {
          this.setState({
            jsonData: { status: 'fail', message: responseData.data.message },
          });
        } else {
          this.setState({
            jsonData: { status: 'fail', message: 'Unknown error' },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          jsonData: { status: 'fail', message: 'Unknown error' },
        });
        // this.setState({
        //   jsonData: { status: 'fail', message: error },
        // });
      });
  };

  render() {
    const {
      jsonData,
      modifiedData,
      itemSearchType,
      itemRange,
      extraOptions,
    } = this.state;
    const { lang } = this.context;
    let results;

    if (jsonData.status === 'fail') {
      results = <h3>{jsonData.message}</h3>;
    } else {
      results = (
        <>
          <SearchResults
            data={modifiedData}
            sortTable={this.sortTable}
            itemSearchType={itemSearchType}
            lang={lang}
          />

          <SearchResultsSimple
            data={modifiedData}
            sortTable={this.sortTable}
            itemSearchType={itemSearchType}
            lang={lang}
          />
        </>
      );
    }

    return (
      <div>
        <form
          className={styles.searchForm}
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <label className={styles.searchFormLabel} htmlFor="selType">
            Search Type
            <select
              id="selType"
              name="selType"
              value={itemSearchType}
              onChange={this.handleType}
            >
              <option value="">Select Type</option>
              <option value="adjectives">Adjective</option>
              <option value="blanks">Blank</option>
              <option value="categories">Category</option>
              <option value="nouns">Noun</option>
              <option value="phrases">Phrase</option>
              <option value="sentences">Sentence</option>
              <option value="verbs">Verb</option>
            </select>
          </label>

          <label className={styles.searchFormLabel} htmlFor="selRange">
            Range
            <select
              id="selRange"
              name="selRange"
              value={itemRange}
              onChange={this.handleRange}
            >
              <option value="">Select Range</option>
              {itemSearchType !== 'categories' && (
                <>
                  <option value="a-c">A - C</option>
                  <option value="d-f">D - F</option>
                  <option value="g-i">G - I</option>
                  <option value="j-l">J - L</option>
                  <option value="m-o">M - O</option>
                  <option value="p-r">P - R</option>
                  <option value="s-u">S - U</option>
                  <option value="v-x">V - X</option>
                  <option value="y-z">Y - Z</option>
                  <option value="umlauts">Umlauts</option>
                </>
              )}

              {extraOptions.length > 0
                && extraOptions.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </label>
          <div className={styles.searchFormBtnSearch}>
            <button
              onClick={this.handleSearchClick}
              type="button"
              onKeyPress={this.handleSearchClick}
            >
              <img src={svgSearch} alt="search" />
            </button>
          </div>
        </form>

        {results}
      </div>
    );
  }
}

Search.contextType = LearningContext;

export default Search;
