import React, {
  useState, useContext, useReducer, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { LearningContext } from '../Context';

// Components
import UpdateSelector from './UpdateSelector';
import FormInput from './FormComponents/FormInput';

import FormSelect from './FormComponents/FormSelect';
import FormMessage from './FormComponents/FormMessage';
import withFormWrap from './withFormWrap';

import styles from './forms.module.css';

const VerbAF = ({
  handleSubmit,
  categoryName,
  modifyType,
  updateId,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    id: '',
    unit: '',
    category: '',
    english: '',
    infinitive: '',
    translation: '',
    type: 'weak',
    separable: 'no',
    reflexive: 'no',
    dative: 'no',
    example: '',
    auxiliary: 'het',
    perfect: '',
    prf_example: '',
    imperfect: '',
    imp_example: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const { categories, lang } = useContext(LearningContext);
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  useEffect(() => {
    if (updateId !== undefined) {
      fetchUpdatedData(`https://deutscherphoenix.com/api/verbs/${updateId}?lang=${lang}`);
    }
  }, [fetchUpdatedData, updateId, lang]);

  useEffect(() => {
    if (updateData.id !== undefined) {
      setFormState(updateData);
    }
  }, [updateData]);

  const clearForm = () => {
    setFormState({
      id: '',
      unit: '',
      category: '',
      english: '',
      infinitive: '',
      translation: '',
      type: 'weak',
      separable: 'no',
      reflexive: 'no',
      dative: 'no',
      example: '',
      auxiliary: '',
      perfect: '',
      prf_example: '',
      imperfect: '',
      imp_example: '',
    });
  };

  const memoizedClearForm = useCallback(clearForm, []);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  const handleIconClick = (e) => {
    const itemId = e.target.getAttribute('data-id');
    fetchUpdatedData(`https://deutscherphoenix.com/api/verbs/${itemId}?lang=${lang}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  const handleTranslation = (e) => {
    setFormState({
      translation: e.target.value,
      infinitive: e.target.value
        .replace(/·/g, '')
        .replace(/\([\wÄÖÜäöü¨ß·-]*\)/i, '')
        .trim(),
    });
  };

  const handleChangeCheck = (e) => {
    const { name } = e.target;
    const newValue = formState[name] === 'yes' ? 'no' : 'yes';
    setFormState({ [name]: newValue });
  };

  const isValid = () => {
    if (
      formState.unit === ''
      || formState.category === ''
      || formState.english === ''
      || formState.infinitive === ''
      || formState.translation === ''
      || formState.example === ''
      || formState.type === ''
      || formState.example === ''
      || formState.auxiliary === ''
      || formState.perfect === ''
      || formState.prf_example === ''
      || formState.imperfect === ''
      || formState.imp_example === ''
    ) {
      return false;
    }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    if (modifyType === 'add') {
      fetchUrl = `https://deutscherphoenix.com/api/verbs?lang=${lang}`;
    } else {
      fetchUrl = `https://deutscherphoenix.com/api/verbs/${formState.id}?lang=${lang}`;
    }

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  const btnValue = `${modifyType.charAt(0).toUpperCase()}${modifyType.substring(1)} 
      ${categoryName.charAt(0).toUpperCase()}${categoryName.substring(1)}`;

  const heading = modifyType === 'update'
    ? 'Update Afrikaans Verbs'
    : 'Add Afrikaans Verbs';
  const gridClass = modifyType === 'update' ? styles.formLayoutGrid : '';
  const fetchUrl = `https://deutscherphoenix.com/api/verbs?lang=${lang}&range=`;

  return (
    <div className={gridClass}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <h3 className={styles.header}>{heading}</h3>

        <div className="form__grid-2">
          <FormSelect
            name="unit"
            label="Unit"
            categories={categories.all.generalGrammar}
            selected={formState.unit}
            handleCategory={handleChange}
          />
          <FormSelect
            name="category"
            label="Category"
            categories={categories.all.general}
            selected={formState.category}
            handleCategory={handleChange}
          />
        </div>

        <label className="form__label--check" htmlFor="separable">
          <input
            id="separable"
            name="separable"
            className="form__check"
            type="checkbox"
            checked={formState.separable === 'yes'}
            onChange={handleChangeCheck}
          />
          Separable
        </label>

        <label className="form__label--check" htmlFor="reflexive">
          <input
            id="reflexive"
            name="reflexive"
            className="form__check"
            type="checkbox"
            checked={formState.reflexive === 'yes'}
            onChange={handleChangeCheck}
          />
          Reflexive
        </label>

        <label className="form__label--check" htmlFor="dative">
          <input
            id="dative"
            name="dative"
            className="form__check"
            type="checkbox"
            checked={formState.dative === 'yes'}
            onChange={handleChangeCheck}
          />
          Dative
        </label>

        <div>
          <label className="form__label--check" htmlFor="upVerbTypeMixed">
            <input
              id="upVerbTypeMixed"
              name="type"
              className="form__check"
              type="radio"
              value="mixed"
              checked={formState.type === 'mixed'}
              onChange={handleChange}
            />
            Mixed
          </label>

          <label className="form__label--check" htmlFor="upVerbTypeStrong">
            <input
              id="upVerbTypeStrong"
              name="type"
              className="form__check"
              type="radio"
              value="strong"
              checked={formState.type === 'strong'}
              onChange={handleChange}
            />
            Strong
          </label>

          <label className="form__label--check" htmlFor="upVerbTypeWeak">
            <input
              id="upVerbTypeWeak"
              name="type"
              className="form__check"
              type="radio"
              value="weak"
              checked={formState.type === 'weak'}
              onChange={handleChange}
            />
            Weak
          </label>
        </div>

        <FormInput
          label="English"
          name="english"
          value={formState.english}
          handleChange={handleChange}
        />

        <FormInput
          label={`Translation - ${formState.infinitive}`}
          name="translation"
          value={formState.translation}
          handleChange={handleTranslation}
        />

        <FormInput
          label="example"
          name="example"
          value={formState.example}
          handleChange={handleChange}
        />

        <label
          className="form__label--check"
          htmlFor="upPerfectAuxiliaryHaben"
        >
          <input
            id="upPerfectAuxiliaryHaben"
            name="auxiliary"
            className="form__check"
            type="radio"
            value="het"
            checked={formState.auxiliary === 'het'}
            onChange={handleChange}
          />
          het
        </label>

        <label
          className="form__label--check"
          htmlFor="upPerfectAuxiliarySein"
        >
          <input
            id="upPerfectAuxiliarySein"
            name="auxiliary"
            className="form__check"
            type="radio"
            value="zijn"
            checked={formState.auxiliary === 'zijn'}
            onChange={handleChange}
          />
          ???
        </label>

        <FormInput
          label="Perfect"
          name="perfect"
          value={formState.perfect}
          handleChange={handleChange}
        />

        <FormInput
          label="Perfect Example"
          name="prf_example"
          value={formState.prf_example}
          handleChange={handleChange}
        />

        <FormInput
          label="Imperfect"
          name="imperfect"
          value={formState.imperfect}
          handleChange={handleChange}
        />

        <FormInput
          label="Imperfect Example"
          name="imp_example"
          value={formState.imp_example}
          handleChange={handleChange}
        />



        <button className="form__button" type="submit">{`${btnValue}`}</button>

        <FormMessage messageValues={messageValues} />
      </form>

      {modifyType === 'update' && (
        <UpdateSelector
          categoryType="range"
          handleIconClick={handleIconClick}
          fetchUrl={fetchUrl}
          propNameDisplay="translation"
          propNameToolTip="english"
        />
      )}
    </div>
  );
};

VerbAF.propTypes = {
  handleSubmit: PropTypes.func,
  categoryName: PropTypes.string,
  modifyType: PropTypes.string,
  updateId: PropTypes.string,
  updateData: PropTypes.objectOf(PropTypes.string),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(VerbAF);
