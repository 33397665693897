import React from 'react';

import homeImage from './flashcard-icon.svg';
import './loading.css';

import styles from './loadingActivity.module.css'

const ErrorCategories = () => (
  <div className={styles.wrapCategoryError}>
    <div className={styles.inner}>
      <div className={styles.image}>
        <img src={homeImage} alt="a country" />
      </div>
      <div className={styles.spin1} />
      <div className={styles.spin2} />
      <p className={styles.message}>
        Unable to load Resources
        <br />
        Try reloading the page
      </p>
    </div>
  </div>
);

export default ErrorCategories;
