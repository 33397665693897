import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h2 style={{ paddingTop: '50px' }}>This section of the Admin seems to be missing!</h2>
      <h2 style={{ paddingTop: '50px' }}>This section of the Admin seems to be missing!</h2>
      <h2 style={{ paddingTop: '50px' }}>This section of the Admin seems to be missing!</h2>
      <h2>Choose something else Witches</h2>
    </div>
  );
}

export default NotFound;
