import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './readingBox.module.css';

const ReadingAnswer = ({ answer }) => {
  const [isTranslationVisible, setisTranslationVisible] = useState(false);
  const translationStyle = isTranslationVisible ? { display: 'block' } : { display: 'none' };
  const btnText = isTranslationVisible ? 'Hide Answer' : 'Show Answer';
  const arrowDirectionClass = isTranslationVisible ? styles.arrowUp : styles.arrowDown;

  // After each click scroll to bottom?
  const toggleTranslation = () => setisTranslationVisible(!isTranslationVisible);

  return (
    <div className={styles.wrap}>
      <button type="button" className={`${styles.button} ${arrowDirectionClass}`} onClick={() => toggleTranslation()}>
        {btnText}
      </button>
      <div className={styles.textWrap} style={translationStyle}>
        {answer}
      </div>
    </div>
  );
}

ReadingAnswer.propTypes = {
  answer: PropTypes.string,
}

export default ReadingAnswer;