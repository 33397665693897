import React from 'react';
import PropTypes from 'prop-types';

import MainContainer from './MainContainer';
import TaskHeading from './TaskHeading';

import styles from './articles.module.css';

const ArticlesFinal = ({ wordList, lang }) => {
  const answerName = 'definiteAnswer';

  const answers = wordList.map((item) => {
    const wordId = item.id;
    const fullAnswer = `${item[answerName]} ${item.word}`;

    return (
      <li key={wordId}>
        {fullAnswer}
      </li>
    )
  });

  return (
    <MainContainer>
      <TaskHeading
        lang={lang}
      />
      {/* <h1>Correct Answers</h1> */}

      <ul className={styles.finalBox}>
        {answers}
      </ul>
    </MainContainer>
  );
};

ArticlesFinal.propTypes = {
  wordList: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
};

export default ArticlesFinal;
