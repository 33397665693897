import React from 'react';

import homeImage from './flashcard-icon.svg';
import './loading.css';

import styles from './loadingActivity.module.css'

const LoadingActivity = () => (
  <div className={styles.wrap}>
    <div className={styles.inner}>
      <div className={styles.image}>
        <img src={homeImage} alt="a country" />
      </div>
      <div className={styles.spin1} />
      <div className={styles.spin2} />
      <p className={styles.message}>Loading Resources</p>
    </div>
  </div>
);

export default LoadingActivity;
