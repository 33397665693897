import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityLink from '../ActivityComponents/ActivityLink';
import ActvityCategories from '../ActivityComponents/ActivityCategories';
import ActivityCategoriesGrammar from '../ActivityComponents/ActivityCategoriesGrammar';
import ComingSoon from '../ComingSoon';
import ErrorCategory from '../../Loading/ErrorCategories';

const Sentences = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} sentence building grammar exercises`,
      desc: `${languageName} sentence exercises to improve ${languageName} writing skills. Exercises for grammar, verbs, nouns and adjectives. Each sentence exercise has a grammar lesson.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  const wrap = {
    textAlign: 'center',
    marginBottom: '15px',
  };

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">

        <div style={wrap}>
          <ActivityLink
            toPath={`/sentences/${lang}/type/random`}
            text="Random"
          />
        </div>

        <ActvityCategories
          activity="sentences"
          lang={lang}
          category="type"
          categoryList={categories[lang].sentence_type}
        />

        <ActivityCategoriesGrammar
          activity="sentences"
          lang={lang}
          categoryList={categories[lang].sentence}
          fromPage="sentencesIndex"
          locationHash={locationHash}
        />
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Sentences`}</h1>

        <p className="section-intro-text">German sentence building exercises. Though it is important to learn German nouns, verbs, adjectives, prepositions, and other words It's just as important to learn German syntax (word order). These exercises will help you build your understanding of German word order and help you to build proper sentences.</p>

        {pageContent}
        <ComingSoon message="More Sentence Exercises Coming Soon!" />
      </section>
    </MainLayout>
  );
};

export default Sentences;
