import React, { useState, useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityAnswer from '../ActivityComponents/ActivityAnswer';
import SentenceWordBtn from './SentenceWordBtn';
import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import FinalMessage from '../FinalMessage';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';

import './sentences.css';

import styles from './sentencesActivity.module.css';

const SentencesActivity = () => {
  const { lang, languageName, categories, actions } = useContext(LearningContext);
  const [isShownMessage, setIsShownMessage] = useState(false);
  const { category, id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [buttonToShow, setButtonToShow] = useState(undefined);
  const [boardHeading, setBoardHeading] = useState('');
  const [sentence, setSentence] = useState('');
  const [sentences, setSentences] = useState([]);
  const [sentenceWordsArray, setSentenceWordsArray] = useState([]);
  const [answerWordsArray, setAnswerWordsArray] = useState([]);
  const [isCorrect, setIsCorrect] = useState(undefined);
  const [answer, setAnswer] = useState('');
  const [currentSentence, setCurrentSentence] = useState(0);
  const [currentItemHeader, setCurrentItemHeader] = useState(1);
  const [totalSentences, setTotalSentences] = useState(0);
  const [numSentencesCorrect, setNumSentencesCorrect] = useState(0);
  const [finalScore, setFinalScore] = useState(90);
  let pageContent = '';
  // let answerContent = '';

  // On mount Fetch Sentences
  useEffect(() => {
    let fetchUrl;

    const sentenceList = (list) => {
      let newHeading;

      if (list[0].type === '1') {
        newHeading = 'Create the Sentence';
      } else if (list[0].type === '2') {
        newHeading = 'Create the Question';
      } else if (list[0].type === '3') {
        newHeading = 'Answer the Question';
      } else {
        newHeading = 'Make something sensible';
      }

      setSentences(list);
      setTotalSentences(list.length);
      setBoardHeading(newHeading);
      setSentence(list[0].sentence);
      //   type: list[0].type,
      //   category: list[0].category,
      setAnswer(list[0].answer1);
      // words: list[0].words,
      setAnswerWordsArray(list[0].words);
      // extra words coming later
    };

    if (category === 'type') {
      fetchUrl = `https://deutscherphoenix.com/api/sentences?lang=${lang}&type=${id}`;
    } else {
      fetchUrl = `https://deutscherphoenix.com/api/sentences?lang=${lang}&fKey=${category}&limit=yes`;
    }

    fetch(fetchUrl)
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success' && responseData.data.length > 0) {
          // sentenceList(responseData.data.slice(0, 2));
          sentenceList(responseData.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
        setIsLoading(false);
        setFetchError(true);
      });
  }, [category, id, lang]);

  // Set title and description
  useEffect(() => {
    if (categories[lang] !== undefined) {
      const { setDocumentHead } = actions;
      const topics = categories[lang].grammarTopics;
      let topicName = '';

      if (category === 'type') {
        topicName = '';
      } else {
        const topicObj = topics.find((obj) => obj.id === category);
        topicName = topicObj.name;
      }

      const headObj = {
        title: `${languageName} ${topicName} | Sentence exercieses`,
        desc: `Improve your ${languageName} sentence building skills. Practice syntax, verb conjugation, adjective declension, noun cases, and more.`,
      };
      setDocumentHead(headObj);
    }
  }, [actions, lang, languageName, categories, category]);

  const answerWordClick = (i) => {
    if (buttonToShow === 'continue' || buttonToShow === 'finish') {
      return;
    }

    const oldArray = answerWordsArray;
    const removedWord = oldArray.splice(i, 1).toString();
    sentenceWordsArray.push(removedWord);

    setAnswerWordsArray(oldArray);
    // setSentenceWordsArray();
    setButtonToShow('check');
    // canCheck: true,

    // this.setState((prevState) => {
    //   const oldArray = prevState.answerWordsArray;
    //   const removedWord = oldArray.splice(i, 1).toString();
    //   prevState.sentenceWordsArray.push(removedWord);

    //   return {
    //     answerWordsArray: oldArray,
    //     // buttonToShow: 'check',
    //     // canCheck: true,
    //   };
    // });
  };

  const sentenceWordClick = (i) => {
    if (buttonToShow === 'continue' || buttonToShow === 'finish') {
      return;
    }

    const oldArray = sentenceWordsArray;
    const removedWord = oldArray.splice(i, 1);
    answerWordsArray.push(removedWord);

    // new needs a fix
    setSentenceWordsArray(oldArray);

    // this.setState((prevState) => {
    //   const oldArray = prevState.sentenceWordsArray;
    //   const removedWord = oldArray.splice(i, 1);
    //   prevState.answerWordsArray.push(removedWord);

    //   return {
    //     sentenceWordsArray: oldArray,
    //   };
    // });
  };

  const handleCheckClick = () => {
    if (answer === sentenceWordsArray.join(' ')) {
      setButtonToShow('continue');
      setIsCorrect(true);
      setNumSentencesCorrect(numSentencesCorrect + 1);
    } else {
      setButtonToShow('continue');
      setIsCorrect(false);
    }
  };

  const handleContinueClick = () => {
    const sentenceNum = currentSentence + 1;

    if (currentSentence < totalSentences - 1) {
      let newHeading;

      if (sentences[sentenceNum].type === '1') {
        newHeading = 'Create the Sentence';
      } else if (sentences[sentenceNum].type === '2') {
        newHeading = 'Create the Question';
      } else if (sentences[sentenceNum].type === '3') {
        newHeading = 'Answer the Question';
      } else {
        newHeading = 'Make something sensible';
      }

      // New
      setButtonToShow(undefined);
      setCurrentSentence(sentenceNum);
      setCurrentItemHeader(sentenceNum + 1);
      setSentenceWordsArray([]);
      setIsCorrect(undefined);
      setBoardHeading(newHeading);
      setSentence(sentences[sentenceNum].sentence);
      // type: prevState.sentences[sentenceNum].type,
      // category: prevState.sentences[sentenceNum].category,
      setAnswer(sentences[sentenceNum].answer1);
      // words: prevState.sentences[sentenceNum].words,
      setAnswerWordsArray(sentences[sentenceNum].words);
    } else {
      setButtonToShow('finish');
      setIsCorrect(undefined);
    }
  };

  const handleFinishClick = () => {
    // Calculate and set final score
    const score = Math.round((numSentencesCorrect / totalSentences) * 100);
    setFinalScore(score);

    setButtonToShow(undefined);
    setIsCorrect(undefined);
    setIsShownMessage(true);
  };

  const sentenceWords = sentenceWordsArray.map((word, i) => (
    <li key={i} className="sentences__item">
      <SentenceWordBtn label={word} id={i} handleClick={sentenceWordClick} />
    </li>
  ));

  const answerWords = answerWordsArray.map((word, i) => (
    <li key={i} className="sentences__item">
      <SentenceWordBtn label={word} id={i} handleClick={answerWordClick} />
    </li>
  ));

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <>
        <div>
          <div className={styles.wrap}>
            <div className="sentences__words">
              <h3 className={styles.heading}>{boardHeading}</h3>
              <p className={styles.sentence}>{sentence}</p>

              <ul className={styles.wordsWrap}>{sentenceWords}</ul>

              <ul className={styles.wordsWrap} style={{ borderTop: '1px dotted #999', borderRadius: 'none' }}>{answerWords}</ul>
            </div>
          </div>
        </div>

        <ActivityAnswer
          buttonToShow={buttonToShow}
          handleCheckClick={handleCheckClick}
          handleContinueClick={handleContinueClick}
          handleFinishClick={handleFinishClick}
          isCorrect={isCorrect}
          answer={answer}
        />

        {
          isShownMessage && (
            <FinalMessage
              type="sentences"
              lang={lang}
              finalScore={finalScore}
              topicId={category}
            />
          )
        }
      </>
    )
  }

  return (
    <ActivityWrap>
      <div>
        <ActivityHeader
          currentItem={currentItemHeader}
          totalItems={totalSentences}
          type="progress"
          page="sentences"
          lang={lang}
        />
      </div>

      {pageContent}
    </ActivityWrap>
  );
};

export default SentencesActivity;
