import React from 'react';
import PropTypes from 'prop-types';

import FormInput from './FormComponents/FormInput';

import styles from './forms.module.css';

const VerbTenses = ({ lang, tense, formState, handleChange }) => {
  const tenseHeading = tense === 'imperfect' ? 'Imperfect' : 'Present';
  const tensePrefix = tense === 'imperfect' ? 'imp_' : '';
  const pronouns = {
    de: {
      i: 'ich',
      you: 'du',
      he: 'er',
      we: 'wir',
      youll: 'ihr',
      they: 'sie'
    },
    nl: {
      i: 'ik',
      you: 'jij',
      he: 'hij',
      we: 'wij',
      youll: 'jullie',
      they: 'zij'
    }
  }

  const dataTipHe = lang === 'de' ? 'er, sie, es' : 'hij, ze, zij, het';
  const dataTipThey = lang === 'de' ? 'sie, Sie' : 'ze, zij';

  const getNameAndLabel = (pronoun) => `${tensePrefix}${pronouns[lang][pronoun]}`;
  const getInputValue = (pronoun) => {
    const valueName =  `${tensePrefix}${pronouns[lang][pronoun]}`;
    let inputVal = formState[valueName];
    return inputVal;
  };
  const getExNameAndLabel = () => `${tensePrefix}example`;
  const getExImputValue = () => {
    const valueName =  `${tensePrefix}example`;
    let inputVal = formState[valueName];
    return inputVal;
  }



  return ( 
    <fieldset className={styles.fieldset}>
          <legend>{tenseHeading}</legend>
          <FormInput
            label={getNameAndLabel('i')}
            name={getNameAndLabel('i')}
            value={getInputValue('i')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={getNameAndLabel('you')}
            name={getNameAndLabel('you')}
            value={getInputValue('you')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={
              (
                <span>
                  {getNameAndLabel('he')}
                  <span className={styles.toolTip} data-tip={dataTipHe}>
                    {' '}
                    ?
                  </span>
                </span>
              )
            }
            name={getNameAndLabel('he')}
            value={getInputValue('he')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={getNameAndLabel('we')}
            name={getNameAndLabel('we')}
            value={getInputValue('we')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={getNameAndLabel('youll')}
            name={getNameAndLabel('youll')}
            value={getInputValue('youll')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={
              (
                <span>
                  {getNameAndLabel('they')}
                  <span className={styles.toolTip} data-tip={dataTipThey}>
                    {' '}
                    ?
                  </span>
                </span>
              )
            }
            name={getNameAndLabel('they')}
            value={getInputValue('they')}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label={getExNameAndLabel()}
            name={getExNameAndLabel()}
            value={getExImputValue()}
            handleChange={handleChange}
          />
        </fieldset>
   );
}

VerbTenses.propTypes = {
  lang: PropTypes.string,
  tense: PropTypes.string,
  formState: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func,
}
 
export default VerbTenses;