import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ErrorArticlesContainer from './ErrorArticlesContainer';
import ErrorArticlesHeading from './ErrorArticlesHeading';
import WordBox from '../../Main/Articles/WordBox';
import ArticleButtons from '../../Main/Articles/ArticleButtons';
import CheckAnswers from '../../Main/Articles/CheckAnswers';
import AnswerBox from '../../Main/Articles/AnswerBox';

const ErrorArticlesActivity = ({ lang, wordList, changeComponent }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [buttonLabel, setButtonLabel] = useState('check');
  const [answer, setAnswer] = useState('');
  const [answerClass, setAnswerClass] = useState('');
  const [answerText, setAnswerText] = useState('');

  const objArticles = {
    de: [
      { id: 1, name: 'der' },
      { id: 2, name: 'die' },
      { id: 3, name: 'das' },
    ],
    nl: [
      { id: 1, name: 'de' },
      { id: 2, name: 'het' },
    ],
  };

  const clearLastAnswer = () => {
    setSelectedArticle('');
    setAnswerClass('');
    setAnswer('');
    setAnswerText('');
  }

  const handleArticleClick = (selectedArticle) => {
    setSelectedArticle(selectedArticle);
  }

  // Previous currentIndexState
  const prevCurrentRef = useRef();
  useEffect(() => {
    prevCurrentRef.current = currentIndex;
  });
  const prevCurrentIndex = prevCurrentRef.current;

  const handleNextClick = () => {
    clearLastAnswer();
    setButtonLabel('check');

    if (prevCurrentIndex < wordList.length - 1) {
      setCurrentIndex(prevCurrentIndex + 1);
    } else {
      setCurrentIndex(prevCurrentIndex);
    }
  }

  const checkAnswer = () => {
    let answerClass;
    const answer = `${wordList[currentIndex]['answer']} ${wordList[currentIndex].word}`;
    let answerText;
    let isAnswerCorrect;

    if (selectedArticle === wordList[currentIndex]['answer']) {
      isAnswerCorrect = true;
      answerClass = 'answer-correct';
      answerText = 'Your answer is correct';
    } else {
      isAnswerCorrect = false;
      answerClass = 'answer-incorrect';
      answerText = 'The correct answer is';
    }

    setAnswerClass(answerClass);
    setAnswer(answer);
    setAnswerText(answerText);

    return isAnswerCorrect;
  }

  const handleCheckClick = () => {
    if (selectedArticle === '') {
      return;
    }

    checkAnswer();

    if (currentIndex < wordList.length - 1) {
      setButtonLabel('next');
    } else {
      setButtonLabel('finish');
    }
  }

  const handleFinishClick = () => {
    changeComponent('final');
  };

  return (
    <ErrorArticlesContainer>
      <ErrorArticlesHeading
        lang={lang}
        hedingType="activity"
      />

      <WordBox
        currentEnglish={wordList[currentIndex].english}
        selectedArticle={selectedArticle}
        currentWord={wordList[currentIndex].word}
      />

      <ArticleButtons
        articles={objArticles[lang]}
        handleArticleClick={handleArticleClick}
      />

      <CheckAnswers
        buttonLabel={buttonLabel}
        handleCheckClick={handleCheckClick}
        handleNextClick={handleNextClick}
        handleFinishClick={handleFinishClick}
      />

      <AnswerBox
        answerClass={answerClass}
        answerText={answerText}
        answer={answer}
      />
    </ErrorArticlesContainer>
  );
}

ErrorArticlesActivity.propTypes = {
  lang: PropTypes.string,
  wordList: PropTypes.arrayOf(PropTypes.shape()),
  changeComponent: PropTypes.func,
};

export default ErrorArticlesActivity;
