import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';
import ReadingBox from './ReadingBox';
import ReadingAnswer from './ReadingAnswer';

import styles from './readingActivity.module.css';

const GrammarActivity = () => {
  const { lang, languageName, actions } = useContext(LearningContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const [heading, setHeading] = useState('');
  const [category, setCategory] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [storyText, setStoryText] = useState([]);
  const [answerText, setAnswerText] = useState('');
  let pageContent = '';
  let answerContent = '';

  // On mount Load story
  useEffect(() => {
    fetch(
      `https://deutscherphoenix.com/api/stories/${id}/?lang=${lang}`,
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          const data = responseData.data[0];

          setHeading(data.title);
          setCategory(data.category);
          setCategoryName(data.categoryName);

          // The order of the patterns in the regular expression matters.
          // const tempEnglishArray = data.english.split(/\r\n|\r|\n/);
          // const tempTranslationArray = data.translation.split(/\r\n|\r|\n/);
          const tempEnglishArray = data.english.split(/\r\n\r\n|\r\r|\n\n/);
          const tempTranslationArray = data.translation.split(/\r\n\r\n|\r\r|\n\n/);

          if (tempEnglishArray.length === tempTranslationArray.length) {
            const tempStoryArray = [];
            for (let i = 0; i < tempEnglishArray.length; i = i + 1) {
              tempStoryArray.push({
                id: i,
                english: tempEnglishArray[i],
                translation: tempTranslationArray[i],
              });
            }

            setStoryText(tempStoryArray);
            setAnswerText(data.answer);

            setIsLoading(false);
            setFetchError(false);
          } else {
            setIsLoading(false);
            setFetchError(true);
          }
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchError(true);
      });
  }, [id, lang]);

  // Set title and description
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${heading} | ${languageName} stories`,
      desc: `Improve your ${languageName} reading and vocabulary skills. Featuring ${languageName} stories, news, fairy tales, and more with english translations.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName, heading]);

  if (answerText !== 'none') {
    answerContent = <ReadingAnswer answer={answerText} />;
  }

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    const readingBoxes = storyText.map((paragraph) => (
      <ReadingBox
        key={paragraph.id}
        category={category}
        englishText={paragraph.english}
        translationText={paragraph.translation}
      />
    ));

    pageContent = (
      <div>
        <div className={styles.wrap}>
          <div>
            <h2 className={styles.heading}>{heading}</h2>
            {readingBoxes}
            {answerContent}
          </div>
        </div>
      </div>
    )
  }

  return (
    <ActivityWrap rows="2" padding={false}>
      <ActivityHeader
        heading={categoryName}
        page="reading"
        lang={lang}
      />
      {pageContent}

    </ActivityWrap>
  );
};

export default GrammarActivity;
