import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './TelegramTextarea.module.css';

const TelegramTextarea = ({
  label, name, value, handleChange, isButtonInsert, cursorPos, type,
}) => {
  const elTextArea = useRef(null);

  const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };
  

  // Emojis
  const emojis = {
    fire: String.fromCodePoint(0x1F525),
    flagDe: getFlagEmoji('DE'),
    flagNl: getFlagEmoji('NL'),
    flagUs: getFlagEmoji('US'),
    grinningFace: String.fromCodePoint(0x1F600),
    jackolatern: String.fromCodePoint(0x1F383),
    link: String.fromCodePoint(0x1F517),
    notebook: String.fromCodePoint(0x1F4D3),
    purpleHeart: String.fromCodePoint(0x1F49C),
    redHeart: String.fromCodePoint(0x2764),
    xmasTree: String.fromCodePoint(0x1F384),

    
    // notebook: String.fromCodePoint(0x),
    // $wavingHandEmoji = "\u{1F44B}";
  };

  // Set Cursor position
  useEffect(() => {
    if (isButtonInsert === true) {
      const refCurrent = elTextArea.current;

      refCurrent.select();
      refCurrent.setSelectionRange(cursorPos, cursorPos);
    }
    
  }, [value, isButtonInsert, cursorPos]);

  const handleTagClick = (e) => {
    const tagType = e.target.getAttribute('data-id');
    const tagArray = ['b', 'i', 'u'];
    const refCurrent = elTextArea.current;
    const selStart = refCurrent.selectionStart;
    const selEnd = refCurrent.selectionEnd;
    let newContent = '';

    // Nothing is selected
    if (selStart === selEnd) {
      return;
    }

    const areaContent = refCurrent.value;

    // write checks as you think of them.
    // if selection starts at zero
    // if selection is in middle
    // if selection is middle to end

    const selectedContent = refCurrent.value.slice(selStart, selEnd);

    if (tagArray.includes(tagType)) {
      newContent = `<${tagType}>${selectedContent}</${tagType}>`;
    } else if (tagType === 'a') {
      newContent = `<${tagType} href="">${selectedContent}</${tagType}>`;
    } else {
      return;
    }
    
    const stringOne = areaContent.substring(0, selStart);
    const stringTwo = areaContent.substring(selEnd);
    const newAreaContent = `${stringOne}${newContent}${stringTwo}`;

    handleChange(newAreaContent, true, selEnd);
    // maybe put cursor back where it is expected to be
    elTextArea.current.focus();
  };

  const handleEmojiClick = (e) => {
    const emojiType = e.target.getAttribute('data-id');
    const newContent = emojis[emojiType];
    const refCurrent = elTextArea.current;
    const selStart = refCurrent.selectionStart;
    const selEnd = refCurrent.selectionEnd;

    // Nothing is selected
    if (selStart !== selEnd) {
      return;
    }

    const areaContent = refCurrent.value;
    const stringOne = areaContent.substring(0, selStart);
    const stringTwo = areaContent.substring(selEnd);
    const newAreaContent = `${stringOne}${newContent}${stringTwo}`;
    const newCursorPostion = selEnd + 2;

    handleChange(newAreaContent, true, newCursorPostion);
  };

  const handleTextareaChange = (e) => {
    const { value } = e.target;
    handleChange(value, false);
  };
  
  return (
    <div className={styles.wrap}>
      <label className={styles.label} htmlFor={name}>
        {label}
        <textarea
          ref={elTextArea}
          id={name}
          name={name}
          className={styles.textarea}
          value={value}
          onChange={handleTextareaChange}
        />
      </label>

      <button type="button" className={styles.button} data-id="b" onClick={handleTagClick}>
        B
      </button>
      <button type="button" className={styles.button} data-id="u" onClick={handleTagClick}>
        U
      </button>
      <button type="button" className={styles.button} data-id="i" onClick={handleTagClick}>
        I
      </button>
      <button type="button" className={styles.button} data-id="a" onClick={handleTagClick}>
        {emojis.link}
      </button>

      <br />

      <button type="button" className={styles.button} data-id="grinningFace" onClick={handleEmojiClick}>
        {emojis.grinningFace}
      </button>
      <button type="button" className={styles.button} data-id="notebook" onClick={handleEmojiClick}>
        {emojis.notebook}
      </button>
      <button type="button" className={styles.button} data-id="fire" onClick={handleEmojiClick}>
        {emojis.fire}
      </button>
      <button type="button" className={styles.button} data-id="jackolatern" onClick={handleEmojiClick}>
        {emojis.jackolatern}
      </button>
      <button type="button" className={styles.button} data-id="xmasTree" onClick={handleEmojiClick}>
        {emojis.xmasTree}
      </button>


      <button type="button" className={styles.button} data-id="redHeart" onClick={handleEmojiClick}>
        {emojis.redHeart}
      </button>
      <button type="button" className={styles.button} data-id="purpleHeart" onClick={handleEmojiClick}>
        {emojis.purpleHeart}
      </button>


      <button type="button" className={styles.button} data-id="flagDe" onClick={handleEmojiClick}>
        {emojis.flagDe}
      </button>
      <button type="button" className={styles.button} data-id="flagNl" onClick={handleEmojiClick}>
        {emojis.flagNl}
      </button>
      <button type="button" className={styles.button} data-id="flagUs" onClick={handleEmojiClick}>
        {emojis.flagUs}
      </button>


    </div>
  );
};

TelegramTextarea.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  isButtonInsert: PropTypes.bool,
  cursorPos: PropTypes.number,
  type: PropTypes.string,
};

export default TelegramTextarea;
