import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';

import styles from './vocabularyActivity.module.css';

const VocabularyActivity = () => {
  const { lang, languageName, actions } = useContext(LearningContext);
  const { pos, unit, category } = useParams();
  const wordsPos = pos.charAt(0).toUpperCase() + pos.slice(1);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [words, setWords] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  let pageContent = '';

  // On mount get vocabulary data
  useEffect(() => {
    fetch(
      `https://deutscherphoenix.com/api/vocabulary?lang=${lang}&pos=${pos}&unit=${unit}&cat=${category}`
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          const catName = responseData.data[0].categoryName;
          const formattedCatName = catName.charAt(0).toUpperCase() + catName.slice(1)
          setCategoryName(formattedCatName);
          setWords(responseData.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchError(true);
        console.log('Error fetching and parsing data', error);
      });
  }, [lang, pos, unit, category]);

  // Set title and description
  useEffect(() => {
    const { setDocumentHead } = actions;
    const tempPos = pos + 's';
    const tempCategoryName = categoryName.toLowerCase();

    const headObj = {
      title: `${languageName} vocabulary - ${tempPos} ${tempCategoryName}`,
      desc: `${languageName} ${tempPos}. Increase your vocabulary, which will improve your reading and understanding. ${languageName} nouns, verbs, and more.`,
    };
    setDocumentHead(headObj);
  }, [actions, categoryName, languageName, pos]);

  // Get list of words
  const tableRows = words.map((word) => (
    <tr className={styles.wordsItem} key={word.id}>
      <td>{word.translation}</td>
      <td>{word.english}</td>
    </tr>
  ));

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h2>{categoryName}</h2>

          <table className={styles.vocabTable}>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <ActivityWrap rows="2" padding={false}>
      <ActivityHeader
        heading={`${wordsPos}s - ${categoryName}`}
        page="vocabulary"
        lang={lang}
      />

      {pageContent}
    </ActivityWrap>
  );
};

export default VocabularyActivity;
