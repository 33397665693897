import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import FormInput from '../../Admin/FormComponents/FormInput';
// import UserMessage from './UserMessage';

import styles from './user.module.css';

const UserSignup = () => {
  const initialFormState = {
    user: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  // const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  // const isValid = () => {
  //   if (
  //     formState.user === ''
  //     || formState.email === ''
  //     || formState.passwordOne === ''
  //     || formState.passwordTwo === ''
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('user', formState.user);
    formData.append('email', formState.email);
    formData.append('passwordOne', formState.passwordOne);
    formData.append('passwordTwo', formState.passwordTwo);

    fetch('https://deutscherphoenix.com/api/userSignup.php', {
      method: 'POST',
      body: formData,
    })
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          // actions.setAdminLogin(true);

          // Set session storage
          // sessionStorage.setItem('isLoggedIn', true);
          // sessionStorage.setItem('jwt', responseData.data.jwt);
          navigate('/login');
        } else {
          // navigate('/');
          // setMessageValues({ message: 'Unable to to add user', status: 'fail' });
          console.log('Unable to add user');
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
      });
  };


  return ( 
    <main>
      <div className={styles.formWrap}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h3 className={styles.header}>Sign Up</h3>

          <FormInput
            label="User"
            name="user"
            value={formState.user}
            handleChange={handleChange}
          />

          <FormInput
            label="Email"
            name="email"
            value={formState.email}
            handleChange={handleChange}
            type="email"
          />

          <FormInput
            label="Password One"
            name="passwordOne"
            value={formState.passwordOne}
            handleChange={handleChange}
            type="password"
          />

          <FormInput
            label="Password Two"
            name="passwordTwo"
            value={formState.passwordTwo}
            handleChange={handleChange}
            type="password"
          />

          <button type="submit" className="form__button">
            Sign Up
          </button>

          {/* <UserMessage messageValues={messageValues} /> */}

        </form>
      </div>
    </main>
   );
}
 
export default UserSignup;
