import React from 'react';
import PropTypes from 'prop-types';

import styles from './activityWrap.module.css';

const ActivityWrap = ({ children, rows, padding }) => {
  let wrapStyle = styles.wrapThreeRows;
  let padStyle = {};

  if (rows === "2") {
    wrapStyle = styles.wrapTwoRows;
  }

  if (padding === false) {
    padStyle = {
      padding: '0',
    }
  }

  return (
    <main className={styles.outerWrap}>
      <div className={styles.activity}>
        <div className={wrapStyle} style={padStyle}>
          {children}
        </div>
      </div>
    </main>
  );
};

ActivityWrap.propTypes = {
  rows: PropTypes.string,
  padding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
};

export default ActivityWrap;
