import React from 'react';
import PropTypes from 'prop-types';

import styles from './articles.module.css';

const MainContainer = ({ children }) => (
  <main>
    <div className={styles.innerContainer}>
      {children}
    </div>
  </main>
);

MainContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default MainContainer;
