import React from 'react';
import PropTypes from 'prop-types';

import styles from './articles.module.css';

const CheckAnswers = ({
  buttonLabel, handleCheckClick, handleNextClick, handleFinishClick,
}) => {
  let buttonToUse;

  if (buttonLabel === 'check') {
    buttonToUse = <button className={styles.btnCheck} type="button" onClick={() => handleCheckClick()}>{buttonLabel}</button>;
  } else if (buttonLabel === 'next') {
    buttonToUse = <button className={styles.btnCheck} type="button" onClick={() => handleNextClick()}>{buttonLabel}</button>;
  } else if (buttonLabel === 'finish') {
    buttonToUse = <button className={styles.btnCheck} type="button" onClick={() => handleFinishClick()}>{buttonLabel}</button>;
  }

  return (
    <div className={styles.checkAnswerBox}>
      {buttonToUse}
    </div>
  );
};

CheckAnswers.propTypes = {
  buttonLabel: PropTypes.string,
  handleCheckClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleFinishClick: PropTypes.func,
};

export default CheckAnswers;
