import React from 'react';

const GrammarDelete = () => {
  return (
    <div>
      <h2 style={{ paddingTop: '50px' }}>Grammar Delete,<br />Ha Ha Ha<br />I haven't needed to do this yet.<br /></h2>
      <h2 style={{ paddingTop: '50px' }}>Delete,<br />Ha Ha Ha<br />I haven't needed to do this yet.<br /></h2>
      <h2 style={{ paddingTop: '50px' }}>Delete,<br />Ha Ha Ha<br />I haven't needed to do this yet.<br /></h2>
      <br />
      <br />
      <h2>Use the back button Witches</h2>
    </div>
  );
};

export default GrammarDelete;
