import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';

import styles from './homeRandomItems.module.css';

const HomeRandomItems = () => {
  const { lang, categories } = useContext(LearningContext);
  const [phraseObj, setPhraseObj] = useState({ english: 'I will keep an eye on you.', translation: 'I will keep an eye on you.' });
  const [verbObj, setVerbObj] = useState({ english: 'to have', translation: 'haben' });
  // const [verbPresentRows, setVerbPresentRows] = useState(<tr></tr>);
  const [fullLanguageName, setFullLanguageName] = useState('German');

  // Set 
  useEffect(() => {
    setPhraseObj(categories.randomItems[lang].phrase);
    setVerbObj(categories.randomItems[lang].verb);

    // Set full language name
    if (lang === 'af') {
      setFullLanguageName('Afrikaans');;
    } else if (lang === 'nl') {
      setFullLanguageName('Dutch');
    } else {
      setFullLanguageName('German');
    }

  }, [lang, categories]);

  return (
    <div className={styles.randomWrap}>
      <div className={styles.itemWrap}>
        <h2>{`${fullLanguageName} Daily Phrase`}</h2>
        <p className={styles.text}>{phraseObj.translation}</p>
        <p className={styles.text}>{`English: ${phraseObj.english}`}</p>
      </div>

      <div className={styles.itemWrap}>
        <h2>{`${fullLanguageName} Daily Verb`}</h2>
        <p className={styles.textVerb}>{verbObj.infinitive} - {verbObj.english}</p>
        <p className={styles.textVerb}>{verbObj.example}</p>
      </div>
    </div>
  );
};

export default HomeRandomItems;
