import React from 'react';
import PropTypes from 'prop-types';

import styles from './blanksOptionBtn.module.css';

const BlanksOptionsBtn = ({ label, wordOptionClicked, handleClick }) => {
  const styleObject = label === wordOptionClicked ? { border: '1px solid #3131d2' } : { border: '1px solid transparent' };

  return (
    <button
      type="button"
      className={styles.button}
      onClick={() => handleClick(label)}
      style={styleObject}
    >
      {label}
    </button>
  )
};

BlanksOptionsBtn.propTypes = {
  label: PropTypes.string,
  wordOptionClicked: PropTypes.string,
  handleClick: PropTypes.func,
};

export default BlanksOptionsBtn;
