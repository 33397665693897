import React, { useState, useContext } from 'react';

import { LearningContext } from '../../Context';


import UserFormLogin from './UserLogin';
import UserFormSignup from './UserSignup';

const UserProfile = () => {
  const { actions } = useContext(LearningContext);
  const [formName, setFormName] = useState('login');
  let userForm = UserFormLogin;

  const handleLogOutClick = () => {
    actions.setUserLogin(false)
  }

  // const handleLogInClick = () => {
  //   console.log('loging in');
  //   setFormName('login');
  // }

  // const handleSignUpClick = () => {
  //  console.log('loging in');
  //  setFormName('signup');
  // }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value });
  // };

  if (formName === 'login') {
    userForm = UserFormLogin;
  } else {
    userForm = UserFormSignup;
  }

  return (
    <main>
      <div>
        <button type="button" onClick={handleLogOutClick}>Log Out</button>    
        {/* <button type="button" onClick={handleLogInClick}>Log In</button>
        <button type="button" onClick={handleSignUpClick}>Sign Up</button> */}

        {userForm}

      </div>
    </main>
   );
}
 
export default UserProfile;