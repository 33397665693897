import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { LearningContext } from '../Context';

import HeaderAdminIcon from './HeaderAdminIcon';

import styles from './header.module.css';
import imgLogo from './logo-01-40x40.png';
import imgNl from '../home-img-nl.svg';
// import imgStreakOn from './star-on.svg';
// import imgStreakOff from './star-off.svg';

const Header = ({ isLangMenuVisible, isActivityMenuVisible, isStudyMenuVisible }) => {
  const {
    lang, isAdminLoggedIn, labels, actions, isStreakOn, streakCount,
  } = useContext(LearningContext);
  const { setLanguage } = actions;
  const navigate = useNavigate();
  const location = useLocation();

  const deLabel = labels['en'].languages.de;
  const nlLabel = labels['en'].languages.nl;
  const afLabel = labels['en'].languages.af;

  const blanksLabel = labels['en'].activities.blanks;
  const cardsLabel = labels['en'].activities.cards;
  const grammarLabel = labels['en'].activities.grammar;
  const readingLabel = labels['en'].activities.reading;
  const sentencesLabel = labels['en'].activities.sentences;
  const wordsLabel = labels['en'].activities.words;

  const toPathAdmin = (isAdminLoggedIn) ? '/admin' : '/';
  // const toPathAdmin = '/';
  const img = lang === 'nl' ? imgNl : imgLogo;
  // const imgStreak = isStreakOn === true ? imgStreakOn : imgStreakOff;
  const imgStreak = "";
  const subLangClass = isLangMenuVisible ? styles.jsShowMenu : '';
  const arrowLangClass = isLangMenuVisible ? styles.arrowUp : styles.arrowDown;

  const subActivityClass = isActivityMenuVisible ? styles.jsShowMenu : '';
  const arrowActivityClass = isActivityMenuVisible ? styles.arrowUp : styles.arrowDown;

  const subStudyClass = isStudyMenuVisible ? styles.jsShowMenu : '';
  const arrowStudyClass = isStudyMenuVisible ? styles.arrowUp : styles.arrowDown;

  const handleLangClick = (newLang) => {
    const languagesArray = ['de', 'nl', 'af'];
    const currentPath = location.pathname;
    const lastSlash = currentPath.lastIndexOf('/');
    let tempPath = currentPath;
    let tempLastSlash = lastSlash;
    let finalText = '';
    let newPath;

    // Set new language
    setLanguage(newLang);

    // If on home page return
    if (currentPath === '/') {
      return;
    }

    // Remove final slash if it's at the end of string
    if (location.pathname.length === lastSlash + 1) {
      tempPath = currentPath.substring(0, lastSlash);
      tempLastSlash = tempPath.lastIndexOf('/');
    }

    finalText = tempPath.substring(tempLastSlash + 1)

    // Final text is not in array return
    if (!languagesArray.includes(finalText) || finalText === newLang) {
      return;
    }

    // Push new path
    newPath = tempPath.substring(0, tempLastSlash + 1) + newLang;
    navigate(newPath);
  }

  return (
    <header className={styles.mainHeader}>

      <div className={`${styles.mainHeaderWrap} contentWrap`}>
        <Link to="/" className={styles.mainHeaderLogo}>
          <img src={img} alt="deutscher phoenix logo" />
        </Link>

        {/* <p className={styles.mainHeaderName}>DP</p> */}

        <nav className={styles.mainHeaderNav}>
          <ul className={styles.mainMenu}>
            <li className={styles.mainMenuItemStreak}>
              <span>
                <img src={imgStreak} alt="streak" style={{'width': '20px', 'height': '20px'}} /> {streakCount}
              </span>
            </li>
            <li id="js-lang" className={styles.mainMenuItem}>
              <span className={arrowLangClass}>Lang</span>
              <ul className={`${styles.subMenu} ${subLangClass}`}>
                <li className={styles.subMenuItem}>
                  <button
                    className={styles.subMenuItemButton}
                    type="button"
                    onClick={() => handleLangClick('de')}
                  >
                    {deLabel}
                  </button>
                </li>
                <li className={styles.subMenuItem}>
                  <button
                    className={styles.subMenuItemButton}
                    type="button"
                    onClick={() => handleLangClick('nl')}
                  >
                    {nlLabel}
                  </button>
                </li>
                <li className={styles.subMenuItem}>
                  <button
                    className={styles.subMenuItemButton}
                    type="button"
                    onClick={() => handleLangClick('af')}
                  >
                    {afLabel}
                  </button>
                </li>

                <li className={styles.subMenuItem}>
                  <img src={imgStreak} alt="streak" style={{'width': '14px', 'height': '14px'}} /> {`Day ${streakCount}`}
                </li>
              </ul>
            </li>

            <li id="js-activity" className={styles.mainMenuItem}>
              <span className={arrowActivityClass}>Activity</span>
              <ul className={`${styles.subMenu} ${subActivityClass}`}>
                <li className={styles.subMenuItem}><NavLink to={`/blanks/${lang}`}>{blanksLabel}</NavLink></li>
                <li className={styles.subMenuItem}><NavLink to={`/cards/${lang}`}>{cardsLabel}</NavLink></li>
                <li className={styles.subMenuItem}><NavLink to={`/reading/${lang}`}>{readingLabel}</NavLink></li>
                <li className={styles.subMenuItem}><NavLink to={`/sentences/${lang}`}>{sentencesLabel}</NavLink></li>
                <li className={styles.subMenuItem}><NavLink to={`/words/${lang}`}>{wordsLabel}</NavLink></li>
              </ul>
            </li>

            <li id="js-study" className={styles.mainMenuItem}>
              <span className={arrowStudyClass}>Study</span>
              <ul className={`${styles.subMenu} ${subStudyClass}`}>
                <li className={styles.subMenuItem}><NavLink to={`/grammar/${lang}`}>{grammarLabel}</NavLink></li>
                <li className={styles.subMenuItem}><NavLink to={`/vocabulary/${lang}`}>Vocabulary</NavLink></li>
              </ul>
            </li>
          </ul>
        </nav>

        <NavLink to={toPathAdmin} className={styles.mainHeaderSettings}>
          <HeaderAdminIcon isAdminLoggedIn={isAdminLoggedIn} />
        </NavLink>
      </div>

    </header>
  );
};

Header.propTypes = {
  isLangMenuVisible: PropTypes.bool,
  isActivityMenuVisible: PropTypes.bool,
  isStudyMenuVisible: PropTypes.bool,
};

export default Header;
