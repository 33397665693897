import React, { Component } from 'react';
import PropTypes from 'prop-types';

import labels from './labelTranslations';

export const LearningContext = React.createContext();
LearningContext.displayName = 'LearningContext';
const { Consumer } = LearningContext;
export const LearningConsumer = Consumer;

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'de',
      languageName: 'German',
      loading: true,
      error: false,
      jsonCategories: {},
      isAdminLoggedIn: false,
      isUserLoggedIn: false,
      areCategoriesSet: false,
      isStreakOn: false,
      streakCount: 0,
      msOneDay: 86400000,
    };
  }

  setStreak = (isStreakOn, streakCount) => this.setState({ isStreakOn, streakCount });

  componentDidMount() {
    const { msOneDay } = this.state;
    const categories = sessionStorage.getItem('categories');
    const isAdminloggedIn = sessionStorage.getItem('isAdminLoggedIn');
    const isUserloggedIn = localStorage.getItem('isUserLoggedIn');
    const practiceStreak = localStorage.getItem('practiceStreak');

    // const msTwoDays = 172800000;
    // const msThreeDays = 259200000;
    // const msFourDays = 345600000;
    // const msfiveDays = 432000000;
    // const msSixDays = 518400000;
    // const msSevenDays = 604800000;

    if (isAdminloggedIn) {
      this.setState({ isAdminLoggedIn: true });
    }

    if (isUserloggedIn) {
      this.setState({ isUserLoggedIn: true });
    }

    if (practiceStreak) {
      const tempStreak = JSON.parse(practiceStreak);
      const currentDate = Date.now();
      const startDate = tempStreak.startDate;
      const lastPracticedDate = tempStreak.lastPracticedDate;
      const streakCount = Math.floor((lastPracticedDate - startDate) / msOneDay);
      const isStreakOn = streakCount > 0 ? true : false;

      const diffPracticeAndStart = lastPracticedDate - startDate;
      const diffPracticedAndCurrentDate = currentDate - lastPracticedDate;
      
      if (diffPracticedAndCurrentDate > msOneDay) {
        this.setStreak(false, 0);
        
        const jsonStreak = {
          startDate: currentDate,
          lastPracticedDate: currentDate,
          savedStreak: streakCount,
          savedStartDate: startDate,
          savedLastPracticed: lastPracticedDate,
        }
  
        localStorage.setItem(
          'practiceStreak',
          JSON.stringify(jsonStreak)
        );
      } else if (diffPracticeAndStart > 0 && diffPracticeAndStart <= msOneDay) {
        this.setStreak(true, 1);
      } else if (streakCount > 1) {
        this.setStreak(isStreakOn, streakCount);
      }
    } else {
      const currentDate = Date.now();

      const jsonStreak = {
        startDate: currentDate,
        lastPracticedDate: currentDate,
      }

      localStorage.setItem(
        'practiceStreak',
        JSON.stringify(jsonStreak)
      );
    }

    if (categories) {
      this.setState({
        jsonCategories: JSON.parse(categories),
        areCategoriesSet: true,
        loading: false,
      });
    } else {
      fetch('https://deutscherphoenix.com/api/categories/app')
        .then((reponse) => reponse.json())
        .then((responseData) => {
          if (responseData.status === 'success') {
            sessionStorage.setItem(
              'categories',
              JSON.stringify(responseData.data),
            );
            this.setState({
              jsonCategories: responseData.data,
              areCategoriesSet: true,
              loading: false,
            });
          } else {
            this.setState({
              areCategoriesSet: false,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log('Error fetching and parsing data', error);

          this.setState({
            areCategoriesSet: false,
            loading: false,
            error: true,
          });
        });
    }
  }

  getCategories = () => {
    this.setState({ loading: true });

    fetch('https://deutscherphoenix.com/api/categories/app')
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          sessionStorage.setItem('categories', JSON.stringify(responseData.data));
          this.setState({
            jsonCategories: responseData.data,
            areCategoriesSet: true,
            loading: false,
          });
        } else {
          this.setState({
            areCategoriesSet: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);

        this.setState({
          areCategoriesSet: false,
          loading: false,
          error: true,
        });
      });
  };

  setAdminLogin = (loggedIn) => {
    if (loggedIn === true) {
      this.setState({ isAdminLoggedIn: true });
    } else {
      this.setState({ isAdminLoggedIn: false });
      sessionStorage.removeItem('isAdminLoggedIn');
    }
  };

  setUserLogin = (userLoggedIn) => {
    if (userLoggedIn === true) {
      this.setState({ isUserLoggedIn: true });
    } else {
      this.setState({ isUserLoggedIn: false });
      localStorage.removeItem('isUserLoggedIn');
    }
  };

  setLanguage = (lang) => {
    const names = {
      de: 'German',
      nl: 'Dutch',
      af: 'Afrikaans',
    };

    this.setState({ lang, languageName: names[lang] });
  };

  setDocumentHead = (headObj) => {
    document.title = headObj.title;
    document.querySelector("meta[property='og:title']").setAttribute('content', headObj.title);

    if (headObj.desc !== '') {
      document.querySelector("meta[name='description']").setAttribute('content', headObj.desc);
      document.querySelector("meta[property='og:description']").setAttribute('content', headObj.desc);
    }
  };

  render() {
    const {
      isAdminLoggedIn, isUserLoggedIn, lang, languageName, jsonCategories, loading, error, areCategoriesSet, msOneDay, isStreakOn, streakCount,
    } = this.state;
    const { children } = this.props;
    const value = {
      categories: jsonCategories,
      lang,
      languageName,
      isAdminLoggedIn,
      isUserLoggedIn,
      loading,
      error,
      labels,
      areCategoriesSet,
      msOneDay,
      isStreakOn,
      streakCount,
      actions: {
        setLanguage: this.setLanguage,
        setAdminLogin: this.setAdminLogin,
        setUserLogin: this.setUserLogin,
        setDocumentHead: this.setDocumentHead,
        getCategories: this.getCategories,
        setStreak: this.setStreak,
      },
    };

    return (
      <LearningContext.Provider value={value}>
        {children}
      </LearningContext.Provider>
    );
  }
}

Provider.propTypes = {
  children: PropTypes.element,
};

/**
 * A higher-order component that wraps the provided component in a Context Consumer component.
 * @param {class} WrappedComponent - A React component.
 * @returns {function} A higher-order component.
 */

export default function withContext(WrappedComponent) {
  return function ContextComponent(props) {
    return (
      <Consumer>
        {(context) => <WrappedComponent {...props} context={context} />}
      </Consumer>
    );
  };
}
