import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import ActivityLink from '../ActivityComponents/ActivityLink';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';

import styles from './grammarActivity.module.css';

// import data from '../../../data/developmentHtml';

const GrammarActivity = () => {
  const { lang, languageName, categories, actions } = useContext(LearningContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [heading, setHeading] = useState('');
  const [grammarContent, setGrammarContent] = useState({ __html: '' });
  const [exercises, setExercises] = useState([]);
  let pageContent = '';

  // Get Grammar data
  useEffect(() => {
    // setGrammarContent({ __html: data });
    // setIsLoading(false);
    fetch(
      `https://deutscherphoenix.com/api/grammar/${id}/?lang=${lang}`,
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          const data = responseData.data[0];
          setHeading(data.topic);
          setGrammarContent({ __html: data.content });

          if (data.blankExercises === '1' || data.sentenceExercises === '1') {
            const tempExercises = [];

            if (data.blankExercises === '1') {
              tempExercises.push(<ActivityLink
                key="b"
                toPath={`/blanks/${lang}/${data.id}`}
                text="Blanks"
                fromPage="grammarActivity"
                topicId={data.id}
                activityType="blanks"
              />);
            }

            if (data.sentenceExercises === '1') {
              tempExercises.push(<ActivityLink
                key="s"
                toPath={`/sentences/${lang}/${data.id}`}
                text="Sentence"
                fromPage="grammarActivity"
                topicId={data.id}
                activityType="sentences"
              />);
            }

            setExercises(tempExercises);
          }

          setIsLoading(false);
          setFetchError(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchError(true);
      });
  }, [id, lang]);

  // Set title and description
  useEffect(() => {
    if (categories[lang] !== undefined) {
      const { setDocumentHead } = actions;
      const topics = categories[lang].grammarTopics;
      const topicObj = topics.find((obj) => obj.id === id);

      const headObj = {
        title: `${languageName} ${topicObj.name} | Deutscher Phoenix`,
        desc: `${languageName} ${topicObj.name}. Study ${languageName} grammar that will help you achieve A1 and A2 level. Including prepositions, past-tense and more.`,
      };
      setDocumentHead(headObj);
    }
  }, [actions, languageName, heading, categories, id, lang]);

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <div className={styles.wrap}>
        <div>
          <div className={styles.content} dangerouslySetInnerHTML={grammarContent} />
          {exercises.length !== 0 && (
            <div className={styles.exercisesWrap}>
              <h3>Practice Exercises</h3>
              {exercises.map((item) => item)}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <ActivityWrap rows="2" padding={false}>
      <ActivityHeader
        heading={heading}
        page="grammar"
        lang={lang}
      />
      {pageContent}

    </ActivityWrap>
  );
};

export default GrammarActivity;
