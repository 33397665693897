import React, { useContext, useState, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import ActivityAnswer from '../ActivityComponents/ActivityAnswer';
import BlanksOptionsBtn from './BlanksOptionBtn';
import FinalMessage from '../FinalMessage';

import Loading from '../../Loading/LoadingActivity';
// import Error from '../../Loading/ErrorActivity';
import ErrorArticlesGame from '../../Loading/ErrorArticlesGame';

import styles from './blanksActivity.module.css';

const BlanksActivity = () => {
  const { lang, languageName, actions, categories } = useContext(LearningContext);
  const [isFinalMessageVisible, setIsFinalMessageVisible] = useState(false);
  const { category } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [buttonToShow, setButtonToShow] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState(undefined);
  const [currentSentence, setCurrentSentence] = useState(0);
  const [currentItemHeader, setCurrentItemHeader] = useState(1);
  const [totalSentences, setTotalSentences] = useState(0);
  const [blanks, setBlanks] = useState([]);
  const [answer, setAnswer] = useState('');
  const [sentenceArray, setSentenceArray] = useState([]);
  const [answerArray, setAnswerArray] = useState([]);
  const [blankIndex, setBlankIndex] = useState('');
  const [wordOptionsArray, setWordOptionsArray] = useState([]);
  const [numSentencesCorrect, setNumSentencesCorrect] = useState(0);
  const [finalScore, setFinalScore] = useState(50);
  const [wordOptionClicked, setWordOptionClicked] = useState('');
  let pageContent = '';

  const makeWordObjects = (words) => {
    const arrWords = [];
    words.split(',').forEach((item, i) => {
      arrWords.push({ id: i, word: item.trim() });
    });
    return arrWords;
  };

  // Get blanks
  useEffect(() => {
    fetch(
      `https://deutscherphoenix.com/api/blanks?lang=${lang}&fKey=${category}&limit=yes`,
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          const data = responseData.data[0];

          setBlanks(responseData.data);
          setSentenceArray(data.sentence.split(' '));
          setAnswer(data.answer);
          setAnswerArray(data.answer.split(' '));
          setBlankIndex(data.sentence.split(' ').indexOf('_'));
          setWordOptionsArray(makeWordObjects(data.words));
          setTotalSentences(responseData.data.length);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
        setIsLoading(false);
        setFetchError(true);
      });
  }, [lang, category]);

  // Set document head information
  useEffect(() => {
    if (categories[lang] !== undefined) {
      const { setDocumentHead } = actions;
      const topics = categories[lang].grammarTopics;
      const topicObj = topics.find((obj) => obj.id === category);

      const headObj = {
        title: `${languageName} ${topicObj.name} | Fill in the blank`,
        desc: `${languageName} ${topicObj.name} fill in the blank exercises. Practice your grammar, verbs, nouns, adjectives,  prepositions, and more.`,
      };
      setDocumentHead(headObj);
    }
  }, [actions, categories, lang, languageName, category]);

  const answerWordClick = (word) => {
    if (buttonToShow === 'continue') {
      return;
    }

    const oldArray = sentenceArray;
    oldArray.splice(blankIndex, 1, word);
    setSentenceArray(oldArray);
    setButtonToShow('check');
    setWordOptionClicked(word);

    // this.setState((prevState) => {
    //   const oldArray = prevState.sentenceArray;
    //   oldArray.splice(blankIndex, 1, word);

    //   return {
    //     sentenceArray: oldArray,
    //     buttonToShow: 'check',
    //   };
    // });
  };

  const handleCheckClick = () => {
    if (sentenceArray.join(' ') === answerArray.join(' ')) {
      setIsCorrect(true);
      setButtonToShow('continue');
      setNumSentencesCorrect(numSentencesCorrect + 1);
    } else {
      setIsCorrect(false);
      setButtonToShow('continue');
    }
  };

  const handleContinueClick = () => {
    if (currentSentence < totalSentences - 1) {
      const sentenceNum = currentSentence + 1;

      setButtonToShow(undefined);
      setCurrentSentence(sentenceNum);
      setCurrentItemHeader(sentenceNum + 1);
      setIsCorrect(undefined);
      setSentenceArray(blanks[sentenceNum].sentence.split(' '));
      setBlankIndex(blanks[sentenceNum].sentence.split(' ').indexOf('_'));
      setAnswer(blanks[sentenceNum].answer);
      setAnswerArray(blanks[sentenceNum].answer.split(' '));
      setWordOptionsArray(makeWordObjects(blanks[sentenceNum].words));
      setWordOptionClicked('');

      // this.setState((prevState) => {
      //   const sentenceNum = prevState.currentSentence + 1;

      //   return {
      //     buttonToShow: undefined,
      //     currentSentence: sentenceNum,
      //     // sentenceWordsArray: [],
      //     isCorrect: undefined,
      //     sentenceArray: blanks[sentenceNum].sentence.split(' '),
      //     blankIndex: blanks[sentenceNum].sentence.split(' ').indexOf('_'),
      //     answer: blanks[sentenceNum].answer,
      //     answerArray: blanks[sentenceNum].answer.split(' '),
      //     wordOptionsArray: makeWordObjects(blanks[sentenceNum].words),
      //   };
      // });
    } else {
      setButtonToShow('finish');
      setIsCorrect(undefined);
    }
  };

  const handleFinishClick = () => {
    // Calculate and set final score
    const score = Math.round((numSentencesCorrect / totalSentences) * 100);
    setFinalScore(score);

    setIsCorrect(undefined);
    setButtonToShow(undefined);
    setIsFinalMessageVisible(true);
  };

  const sentenceWithBlanks = sentenceArray.map((word, i) => {
    if (word === '_') {
      return (
        <span key={i} className={styles.sentenceWordBlank}>
          {' '}
          &nbsp;
          {' '}
        </span>
      );
    }

    if (i === blankIndex) {
      return (
        <span key={i} className={styles.sentenceWordUnderlined}>
          {word}
          {' '}
        </span>
      );
    }

    return (
      <span key={i} className={styles.sentenceWord}>
        {word}
        {' '}
      </span>
    );
  });

  const wordOptions = wordOptionsArray.map((word) => (
    <li key={word.id} className={styles.blankOptionsLi}>
      <BlanksOptionsBtn
        label={word.word}
        wordOptionClicked={wordOptionClicked}
        handleClick={answerWordClick} />
    </li>
  ));

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <ErrorArticlesGame lang={lang} />;
  } else {
    pageContent = (
      <>
        <div>
          <div className={styles.wrap}>
            <h3 className={styles.heading}>Fill in the Blank</h3>
            <p className={styles.sentence}>{sentenceWithBlanks}</p>

            <ul className={styles.blankOptionsUl}>{wordOptions}</ul>
          </div>
        </div>

        <ActivityAnswer
          buttonToShow={buttonToShow}
          handleCheckClick={handleCheckClick}
          handleContinueClick={handleContinueClick}
          handleFinishClick={handleFinishClick}
          isCorrect={isCorrect}
          answer={answer}
        />

        {isFinalMessageVisible && (
          <FinalMessage
            type="blanks"
            lang={lang}
            finalScore={finalScore}
            topicId={category}
          />
        )}
      </>
    )
  }

  return (
    <ActivityWrap>
      <ActivityHeader
        currentItem={currentItemHeader}
        totalItems={totalSentences}
        type="progress"
        heading="test"
        lang={lang}
        page="blanks"
      />

      {pageContent}
    </ActivityWrap>
  );
};

export default BlanksActivity;
