import React from 'react';
import PropTypes from 'prop-types';

import styles from './articlesContainer.module.css';

const ArticlesContainer = ({ children }) => (
  <div className={styles.wrap}>
    <div className={styles.innerContainer}>
      {children}
    </div>
  </div>
);

ArticlesContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default ArticlesContainer;
