import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Navigate,
  Routes,
} from 'react-router-dom';
import { LearningContext } from './Context';

// Components
import Home from './Main/Home';
import Admin from './Admin';
import AdminLoginForm from './Admin/AdminLoginForm';
import Articles from './Main/Articles';
import Blanks from './Main/Blanks';
import BlanksActivity from './Main/Blanks/BlanksActivity';
import Cards from './Main/Cards';
import CardActivity from './Main/Cards/CardActivity';
import Grammar from './Main/Grammar';
import GrammarActivity from './Main/Grammar/GrammarActivity';
import Reading from './Main/Reading';
import ReadingActivity from './Main/Reading/ReadingActivity';
import Sentences from './Main/Sentences';
import SentencesActivity from './Main/Sentences/SentencesActivity';
import UserLogin from './Main/User/UserLogin';
import UserProfile from './Main/User/UserProfile';
import UserSignup from './Main/User/UserSignup';
import Vocabulary from './Main/Vocabulary';
import VocabularyActivity from './Main/Vocabulary/VocabularyActivity';
import Words from './Main/Words';
import WordsActivity from './Main/Words/WordsActivity';
import NotFound from './Main/NotFound';
import Privacy from './Main/privacy';

const RoutesComponent = ({ isGrammarMenuShown }) => {
  const { isUserLoggedIn, isAdminLoggedIn } = useContext(LearningContext);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/articles/:language" element={<Articles />} />

      <Route path="/blanks/:language/:category/:id?" element={<BlanksActivity />} />

      <Route path="/blanks/:language" element={<Blanks />} />

      <Route path="/cards/:language/:pos/:unit/:category" element={<CardActivity />} />

      <Route path="/cards/:language/:pos/:category" element={<CardActivity />} />

      <Route path="/cards/:language" element={<Cards />} />

      <Route path="/grammar/:language/:id" element={<GrammarActivity />} />

      <Route path="/grammar/:language" element={<Grammar />} />

      <Route path="/reading/:language/:id" element={<ReadingActivity />} />

      <Route path="/reading/:language" element={<Reading />} />

      <Route path="/sentences/:language/:category/:id?" element={<SentencesActivity />} />

      <Route path="/sentences/:language" element={<Sentences />} />

      <Route path="/login/" element={<UserLogin />} />

      <Route path="/privacy" element={<Privacy />} />

      <Route
        path="/profile"
        element = {!isUserLoggedIn ? <Navigate to="/login" /> : <UserProfile />}
      />

      <Route path="/signup/" element={<UserSignup />} />

      <Route path="/vocabulary/:language/:pos/:unit/:category?" element={<VocabularyActivity />} />

      <Route path="/vocabulary/:language" element={<Vocabulary />} />

      <Route path="/words/:language/:pos/:unit/:category?" element={<WordsActivity />} />

      <Route path="/words/:language" element={<Words />} />

      <Route path="/admin-login" element={<AdminLoginForm />} />

      <Route
        path="/admin/*"
        element = {!isAdminLoggedIn ? <Navigate to="/admin-login" /> : <Admin />}
      />

      <Route path="*" element={<NotFound />} />

    </Routes>
  );
};

RoutesComponent.propTypes = {
  isGrammarMenuShown: PropTypes.bool,
};

export default RoutesComponent;
