import React, {
  useState, useContext, useReducer, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { LearningContext } from '../Context';

// Components
import UpdateSelector from './UpdateSelector';
import FormSelect from './FormComponents/FormSelect';
import FormInput from './FormComponents/FormInput';
import FormTextarea from './FormComponents/FormTextarea';
import FormMessage from './FormComponents/FormMessage';
import withFormWrap from './withFormWrap';

import styles from './forms.module.css';

const Grammar = ({
  handleSubmit,
  categoryName,
  modifyType,
  updateId,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    id: '',
    category: '',
    topic: '',
    content: '',
    position: '',
    blankExercises: false,
    sentenceExercises: false
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const { categories, lang, labels } = useContext(LearningContext);
  const { en } = labels;
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  // Get update data. From search page click
  useEffect(() => {
    if (updateId !== undefined) {
      fetchUpdatedData(`https://deutscherphoenix.com/api/grammar/${updateId}?lang=${lang}`);
    }
  }, [fetchUpdatedData, updateId, lang]);

  useEffect(() => {
    if (updateData.id !== undefined) {
      setFormState(updateData);

      setFormState({
        blankExercises: updateData.blankExercises === '1' ? true : false,
        sentenceExercises: updateData.sentenceExercises === '1' ? true : false,
      });
    }
  }, [updateData]);

  const clearForm = () => {
    setFormState({
      id: '',
      category: '',
      topic: '',
      content: '',
      position: '',
    });
  };

  const memoizedClearForm = useCallback(clearForm, []);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  const handleIconClick = (e) => {
    const itemId = e.target.getAttribute('data-id');
    fetchUpdatedData(`https://deutscherphoenix.com/api/grammar/${itemId}?lang=${lang}`);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormState({ [name]: newValue });
  };

  const isValid = () => {
    if (
      formState.category === ''
      || formState.topic === ''
      || formState.content === ''
    ) {
      return false;
    }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    if (modifyType === 'add') {
      fetchUrl = `https://deutscherphoenix.com/api/grammar?lang=${lang}`;
    } else {
      fetchUrl = `https://deutscherphoenix.com/api/grammar/${formState.id}?lang=${lang}`;
    }

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  const btnValue = `${modifyType.charAt(0).toUpperCase()}${modifyType.substring(1)}
       ${categoryName.charAt(0).toUpperCase()}${categoryName.substring(1)}`;

  const langName = en.languages[lang];
  const heading = modifyType === 'update'
    ? `Update ${langName} Grammar`
    : `Add ${langName} Grammar`;
  const gridClass = modifyType === 'update' ? styles.formLayoutGrid : '';
  const fetchUrl = `https://deutscherphoenix.com/api/grammar?lang=${lang}&cat=`;

  return (
    <div className={gridClass}>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <h3 className={styles.header}>{heading}</h3>

        <FormSelect
          name="category"
          label="Category"
          categories={categories.all.generalGrammar}
          selected={formState.category}
          handleCategory={handleChange}
        />

        <FormInput
          label="Topic"
          name="topic"
          value={formState.topic}
          handleChange={handleChange}
        />

        <FormTextarea
          label="Content"
          name="content"
          value={formState.content}
          handleChange={handleChange}
          type="large"
        />

        <FormInput
          label="Position"
          name="position"
          value={formState.position}
          handleChange={handleChange}
        />

        <fieldset className={styles.fieldset}>
          <legend>Exercises</legend>
          <label htmlFor="blankExercises">
            <input
              id="blankExercises"
              type="checkbox"
              name="blankExercises"
              checked={formState.blankExercises}
              onChange={handleChange}
            />
            Blanks
          </label>

          <label htmlFor="sentenceExercises">
            <input
              id="sentenceExercises"
              type="checkbox"
              name="sentenceExercises"
              checked={formState.sentenceExercises}
              onChange={handleChange}
            />
            Sentences
          </label>
        </fieldset>

        <button className="form__button" type="submit">{`${btnValue}`}</button>

        <FormMessage messageValues={messageValues} />
      </form>

      {modifyType === 'update' && (
        <UpdateSelector
          categoryType="grammar"
          handleIconClick={handleIconClick}
          fetchUrl={fetchUrl}
          propNameDisplay="topic"
          propNameToolTip="position"
        />
      )}
    </div>
  );
};

Grammar.propTypes = {
  handleSubmit: PropTypes.func,
  categoryName: PropTypes.string,
  modifyType: PropTypes.string,
  updateId: PropTypes.string,
  updateData: PropTypes.objectOf(PropTypes.string),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(Grammar);
