const articlesData = {
  status: 'success',
  data: {
    words: {
      de: [
        {
          id: 1,
          word: 'Hund',
          english: 'dog',
          answer: 'der',
        },
        {
          id: 2,
          word: 'Katze',
          english: 'cat',
          answer: 'die',
        },
        {
          id: 3,
          word: 'Schwein',
          english: 'pig',
          answer: 'das',
        },
      ],
      nl: [
        {
          id: 1,
          word: 'Hond',
          english: 'dog',
          answer: 'de',
        },
        {
          id: 2,
          word: 'Kat',
          english: 'cat',
          answer: 'de',
        },
        {
          id: 3,
          word: 'Varken',
          english: 'pig',
          answer: 'het',
        },
      ],
    },
  },
};

export default articlesData;
