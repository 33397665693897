import React from 'react';
import PropTypes from 'prop-types';

import styles from './articles.module.css';

const AnswerBox = ({ answerClass, answerText, answer }) => (
  <div className={`${styles.answerBox} ${answerClass}`}>
    <div>
      <p className={styles.answerBoxText}>{answerText}</p>
      <p className={styles.answerBoxAnswer}>{answer}</p>
    </div>
  </div>
);

AnswerBox.propTypes = {
  answerClass: PropTypes.string,
  answerText: PropTypes.string,
  answer: PropTypes.string,
};

export default AnswerBox;
