import React, { useContext } from 'react';
import { LearningContext } from '../../Context';

import styles from './homeImage.module.css';

import imgDE from './germany.jpg';
import imgDE1 from './germany-header-hero-1600x500-60.jpg';
// import imgNL from './netherlands.jpg';
import imgNL1 from './kinderdijk-header-hero-1600x500-60.jpg';
// import imgAF from './africa.jpg';
import imgAF1 from './africa-header-hero-1600x500-60.jpg';

const HomeImage = () => {
  const value = useContext(LearningContext);
  let img;

  switch (value.lang) {
    case 'de':
      img = imgDE1;
      break;
    case 'nl':
      img = imgNL1;
      break;
    case 'af':
      img = imgAF1;
      break;
    default:
      img = imgDE;
      break;
  }

  return (
    <div className={styles.imgWrap}>
      <h1>Deutscher Phoenix</h1>
      <img className={styles.homeImg} src={img} alt="flashcard logo" />
    </div>
  );
};

export default HomeImage;
