import React from 'react';
import PropTypes from 'prop-types';

import DonateAside from './Donate/DonateAside';
// import AsideAd from './AdSense/AsideAd';

import styles from './mainLayout.module.css';

const MainLayout = ({ children }) => {

  return (
    <main className={styles.outerWrap}>
      <div className={styles.innerWrap}>
        {children}
        <aside className={styles.aside}>
          <DonateAside />
          {/* <AsideAd /> */}
        </aside>
      </div>
    </main>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element,
}

export default MainLayout;