import React from 'react';

import GofundmeButton from './GofundmeButton';
import donateImg1 from './dreieich-600x338-50.jpg';
import donateImg2 from './neuschwanstein-600x338-50.jpg';
import donateImg3 from './brandenburger-tor-600x338-50.jpg';
import donateImg4 from './girl-600x338-50.jpg';
import donateImg5 from './lebkuchen-600x338-50.jpg';
import donateImg6 from './oktoberfest-600x338-50.jpg';

import styles from './donateFinalMesssage.module.css';

const DonateFinalGoFundMe = () => {
  const donateImg7 = donateImg4;
  const arrayImages = [donateImg1, donateImg2, donateImg3, donateImg4, donateImg5, donateImg6, donateImg7];
  const arrayAdText = [
    'Help me study in Germany',
    'Help me achieve my dream',
    'Help me study in Germany',
  ];
  const rndmImgNum = Math.floor(Math.random() * 7);
  const rndmTxtNum = Math.floor(Math.random() * 3);
  const donateImge = arrayImages[rndmImgNum];
  const donateText = arrayAdText[rndmTxtNum];


  return (
    <div className={styles.wrap}>
      <p className={styles.donateP}>{donateText}</p>
      <div className={styles.donateImgWrap}>
        <img src={donateImge} alt="Achieve your Dreams" />
      </div>
      <p className={styles.donateP}>Even $2 or $3 will help</p>
      <GofundmeButton />
    </div>
  );
};

export default DonateFinalGoFundMe;
