import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../Context';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import DonateFinal from './Donate/DonateFinal';

import styles from './finalMessage.module.css';

const FinalMessage = ({ type, lang, finalScore, topicId, pos }) => {
  const { msOneDay, actions } = useContext(LearningContext);
  const { setStreak } = actions;
  let location = useLocation();
  // const params = useParams();
  // const topicId = params['category'];
  let messageScore = '';
  // let messageText = '';
  let newFinalScore = finalScore;
  let studyPageTo = `/grammar/${lang}`;
  let continuePagePath = `/grammar/${lang}`;
  let studyPageLabel = 'Grammar';
  let continuePageLabel = 'Continue';
  let scoreClass = styles.scoreFail;

  // Set streak information
  useEffect(() => {
    const practiceStreak = localStorage.getItem('practiceStreak');

    const currentDate = Date.now();
    const tempStreak = JSON.parse(practiceStreak);
    const startDate = tempStreak.startDate;
    const lastPracticedDate = currentDate;
    const streakCount = Math.floor((lastPracticedDate - startDate) / msOneDay);
    const isStreakOn = streakCount > 0 ? true : false;
    const jsonStreak = {
      startDate: startDate,
      lastPracticedDate: currentDate,
    }

    const diffPracticeAndStart = lastPracticedDate - startDate;

    if (diffPracticeAndStart > 0 && diffPracticeAndStart <= msOneDay) {
      setStreak(true, 1);
    }

    if (streakCount > 1) {
      setStreak(isStreakOn, streakCount);
    }

    localStorage.setItem(
      'practiceStreak',
      JSON.stringify(jsonStreak)
    );

  }, [setStreak, msOneDay]);

  // let newLocation;
  // let newLocation = `/${page}/${lang}`;

  // console.log('in final message, location.state', location.state);

  if (location.state !== undefined) {
    const { fromPage, unitId, activityType } = location.state;
    // console.log('in final message, location state: ', location.state);

    // From page indexes list
    const fromPageIndexesListObject = {
      blanksIndex: 'blanks',
      readingIndex: 'reading',
      sentencesIndex: 'sentences',
      wordsIndex: 'words',
      grammarIndex: 'grammar',
      vocabularyIndex: 'vocabulary',
    };

    const fromPageIndexesListKeys = Object.keys(fromPageIndexesListObject);

    // From page Activities list
    const fromPageActivitiesListObject = {
      grammarActivity: 'grammar',
      vocabularyActivity: 'vocabulary',
    };

    const fromPageActivitiesListKeys = Object.keys(fromPageActivitiesListObject);

    if (fromPageIndexesListKeys.includes(fromPage)) {
      if (type !== 'words') {
        studyPageTo = {
          pathname: `/grammar/${lang}/${topicId}`,
          state: {
            fromPage: 'grammarIndex',
            unitId
          }
        }
      } else {
        studyPageTo = {
          pathname: `/vocabulary/${lang}/${pos}/${unitId}/${topicId}`,
          state: {
            fromPage: 'vocabularyIndex',
            unitId
          }
        }

        studyPageLabel = 'Vocabulary';
        continuePageLabel = 'Words';
      }

      continuePagePath = {
        pathname: `/${fromPageIndexesListObject[fromPage]}/${lang}`,
        hash: unitId,
      }
    } else if (fromPageActivitiesListKeys.includes(fromPage)) {
      studyPageTo = {
        pathname: `/grammar/${lang}/${topicId}`,
        state: {
          fromPage: 'grammarIndex',
          unitId
        }
      }

      if (activityType === 'blanks') {
        continuePagePath = {
          pathname: `/${activityType}/${lang}`,
          hash: unitId,
        }

        continuePageLabel = 'Blanks';
      } else {
        continuePagePath = {
          pathname: `/${activityType}/${lang}`,
          hash: unitId,
        }
        continuePageLabel = 'Sentences';
      }

    }

    // if (type === 'words') {
    //   studyPageTo = {
    //     pathname: `/vocabulary/${lang}/${topicId}`,
    //     state: {
    //       fromPage: 'vocabularyIndex',
    //       unitId
    //     }
    //   }

    //   studyPageLabel = 'Vocabulary';
    //   continuePageLabel = 'Words';
    // }
  }


  // Set no lower than 0
  if (finalScore < 0) {
    newFinalScore = 0;
  }

  // Set no higher than 100
  if (finalScore > 100) {
    newFinalScore = 100;
  }

  if (newFinalScore >= 80) {
    messageScore = `${newFinalScore}%, Great Job!`;
    // messageText = '';
    scoreClass = styles.scorePass;
  } else if (newFinalScore < 80 && newFinalScore >= 60) {
    messageScore = `${newFinalScore}%, not bad`;
    // messageText = <>Vist the Grammar section for some review</>;
    scoreClass = styles.scorePass;
  } else {
    messageScore = `${newFinalScore}%, too bad`;
    // messageText = <>You need some review<br />
    // Please visit the grammar section</>;
  }

  return (
    <div className={styles.finalMessage}>
      <p className={scoreClass}>{messageScore}</p>
      {/* <p className={styles.pText}>{messageText}</p> */}

      <DonateFinal />

      <div className={styles.buttonGrid}>
        <div>
          <Link to={continuePagePath} className={styles.button}>{continuePageLabel}</Link>
        </div>
        <div>
          <Link to={studyPageTo} className={styles.button}>{studyPageLabel}</Link>
        </div>
      </div>
    </div>
  );
};

FinalMessage.propTypes = {
  type: PropTypes.string,
  lang: PropTypes.string,
  finalScore: PropTypes.number.isRequired,
  topicId: PropTypes.string,
  pos: PropTypes.string,
};

export default FinalMessage;
