import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';

import ActivityWrap from '../ActivityComponents/ActivityWrap';
import ActivityHeader from '../ActivityComponents/ActivityHeader';
import CardFaceWrap from './CardFaceWrap';
import CardControls from './CardControls';
import { LearningContext } from '../../Context';
import Loading from '../../Loading/LoadingActivity';
import Error from '../../Loading/ErrorActivity';

import './card.css';

const CardActivity = () => {
  const { lang, languageName, categories, actions } = useContext(LearningContext);
  const { pos, unit, category } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [currentCard, setCurrentCard] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [flipClass, setFlipClass] = useState('');
  const [exampleClass, setExampleClass] = useState('');
  const [exampleText, setExampleText] = useState('Examples');
  let pageContent = '';

  useEffect(() => {
    const verbTenses = ['present', 'perfect', 'imperfect'];
    const verbGroups = ['separable', 'reflexive', 'dative'];
    let fetchUrl;

    // Get cards data
    if (pos === 'adjective') {
      fetchUrl = `https://deutscherphoenix.com/api/adjectivecards?lang=${lang}&unit=${unit}&cat=${category}`;
    } else if (pos === 'noun') {
      fetchUrl = `https://deutscherphoenix.com/api/nouncards?lang=${lang}&unit=${unit}&cat=${category}`;
    } else if (pos === 'gender') {
      fetchUrl = `https://deutscherphoenix.com/api/nouncards?lang=${lang}&unit=${unit}&gender=${category}`;
    } else if (pos === 'phrase') {
      fetchUrl = `https://deutscherphoenix.com/api/phrasecards?lang=${lang}&tId=${category}`;
    } else if (pos === 'verb' && verbTenses.includes(pos)) {
      fetchUrl = `https://deutscherphoenix.com/api/verbcards?lang=${lang}&tense=${category}`;
    } else if (pos === 'verb' && verbGroups.includes(pos)) {
      fetchUrl = `https://deutscherphoenix.com/api/verbcards?lang=${lang}&${category}=yes`;
    } else {
      fetchUrl = `https://deutscherphoenix.com/api/verbcards?lang=${lang}&unit=${unit}&cat=${category}`;
    }

    fetch(fetchUrl)
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          setCardData(responseData.data);
          setCurrentCard(0);
          setTotalCards(responseData.data.length);
          setIsLoading(false);
        } else if (responseData.status === 'fail') {
          setFetchError(true);
          setIsLoading(false);
        } else {
          setFetchError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setFetchError(true);
        setIsLoading(false);
      });
  }, [lang, pos, unit, category]);

  // Set document head information
  useEffect(() => {
    if (categories[lang] !== undefined) {
      const { setDocumentHead } = actions;
      const categoriesArray = categories[lang][pos];
      let tempTitle = '';
      let categoryObj = {};

      if (pos === 'verb') {
        tempTitle = `${languageName} ${category} ${category}s | flashcards`
      } else if (pos === 'phrase') {
        tempTitle = `${languageName} ${category}es flashcards`
      } else {
        categoryObj = categoriesArray.find((obj) => obj.id === category);
        tempTitle = `${languageName} ${category}s ${categoryObj.name} | flashcards`
      }

      const headObj = {
        title: tempTitle,
        desc: `${languageName} ${categoryObj.name} flashcards. Use flashcards to practice your vocabulary - verbs, nouns, adjectives, adverbs, and more.`,
      };
      setDocumentHead(headObj);
    }
  }, [actions, categories, lang, languageName, pos, category]);

  const handleFlipClick = () => {
    flipClass === 'js-flip-card' ? setFlipClass('') : setFlipClass('js-flip-card');
  };

  const handleExamplesToggle = () => {
    if (exampleClass === '') {
      setExampleClass('showExamples');
      setExampleText('Conjugation');
    } else {
      setExampleClass('');
      setExampleText('Examples');
    }
  };

  const cardNext = () => {
    if (currentCard !== totalCards - 1) {
      setFlipClass('');
      setExampleClass('');

      // Delay is so the new word isn't shown before the flip is complete
      if (flipClass === '') {
        setCurrentCard(currentCard + 1);
        setExampleText('Examples');
      } else {
        // Delay based on CSS transitioin time
        setTimeout(() => {
          setCurrentCard(currentCard + 1);
          setExampleText('Examples');
        }, 600);
      }
    }
  };

  const cardBack = () => {
    if (currentCard !== 0) {
      setFlipClass('');
      setExampleClass('');

      // Delay is so the new word isn't shown before the flip is complete
      if (flipClass === '') {
        setCurrentCard(currentCard - 1);
        setExampleText('Examples');
      } else {
        // Delay based on CSS transitioin time
        setTimeout(() => {
          setCurrentCard(currentCard - 1);
          setExampleText('Examples');
        }, 600);
      }
    }
  };

  const newCurrentCard = currentCard + 1;

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <div className="cardsWrap">
        <CardFaceWrap
          cardData={cardData[currentCard]}
          handleFlipClick={handleFlipClick}
          handleExamplesToggle={handleExamplesToggle}
          flipClass={flipClass}
          exampleClass={exampleClass}
          exampleText={exampleText}
          pos={pos}
          category={category}
          lang={lang}
        />
        <CardControls
          currentCard={newCurrentCard}
          totalCards={totalCards}
          cardBack={cardBack}
          cardNext={cardNext}
        />
      </div>
    )
  }
  // Change header to normal header if loading error happens?
  return (
    <ActivityWrap rows="2">

      <ActivityHeader
        currentItem={newCurrentCard}
        totalItems={totalCards}
        type="progress"
        lang={lang}
        page="cards"
      />

      {pageContent}
    </ActivityWrap>
  );
};

export default CardActivity;
