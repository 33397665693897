import React, { useEffect, createRef, useState } from 'react';
import PropTypes from 'prop-types';

import ActivityLink from './ActivityLink';
// import CategoryAd from '../AdSense/CategoryAd';
import TableOfContents from '../TableOfContents';

import styles from './activityCategories.module.css';

const ActvityCategoriesGrammar = ({
  activity, lang, pos, categoryList, fromPage, locationHash,
}) => {
  const [isTableOfContentsShown, setIsTableOfContentsShown] = useState(false);
  const handleButtonClick = () => setIsTableOfContentsShown(true);
  const handleTocClose = (isShown) => setIsTableOfContentsShown(isShown);

  // Create Refs object
  const objRefs = categoryList.reduce((acc, item) => {
    acc[item.id] = createRef();
    return acc;
  }, {});

  // Array to filter for hash number search
  // const locationHashSearchArray = categoryList.map((item) => {
  //   const topics = item.topics.map((topic) => topic.tId);
  //   return { id: item.id, topics };
  // });

  // Scroll to section if locationHash is not undefined
  useEffect(() => {
    if (locationHash !== undefined) {
      let hashSectionId = locationHash.replace('#', '');
      objRefs[hashSectionId].current.scrollIntoView({ behavior: 'auto' });
    }
  }, [locationHash, objRefs]);


  // Get Sections
  let sectionCount = 0;

  const sections = categoryList.map((item) => {
    sectionCount = sectionCount + 1;
    const sectionTopics = item.topics.map((topic) => {
      let toPath = `/${activity}/${lang}/${topic.tId}`;

      if (pos === 'phrase') {
        toPath = `/${activity}/${lang}/${pos}/${topic.tId}`;
      }
      return (
        <ActivityLink
          key={topic.tId}
          toPath={toPath}
          text={topic.name}
          id={`${topic.tId}`}
          fromPage={fromPage}
          unitId={item.id}
          completed={topic.completed}
        />
      )
    });

    
    if (sectionCount === 5) {
      return (
        <React.Fragment key={item.id}>
          <div
            key={item.id}
            ref={objRefs[item.id]}
            className={styles.wrap}
          >
            <h2 className="h2Unit"><span>{item.name}</span></h2>
            <div className={styles.btnWrap}>
              {sectionTopics}
            </div>
            {/* <CategoryAd /> */}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          key={item.id}
          ref={objRefs[item.id]}
          className={styles.wrap}
        >
          <h2 className="h2Unit"><span>{item.name}</span></h2>
          <div className={styles.btnWrap}>
            {sectionTopics}
          </div>
        </div>
      );
    }
  });







  return (
    <div>
      <button
        className={styles.btnToc}
        type="button"
        onClick={() => handleButtonClick()}
      >
        Table of Contents
      </button>

      {isTableOfContentsShown === true && (
        <TableOfContents
          handleTocClose={handleTocClose}
          categoryList={categoryList}
          fromPage={fromPage}
          activity={activity}
          lang={lang}
        />
      )}

      {sections}
    </div>
  );
};

ActvityCategoriesGrammar.defaultProps = {
  categoryList: '',
};

ActvityCategoriesGrammar.propTypes = {
  activity: PropTypes.string,
  lang: PropTypes.string,
  pos: PropTypes.string,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  locationHash: PropTypes.string,
};

export default ActvityCategoriesGrammar;
