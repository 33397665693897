import React from 'react';

import PaypalButton from './PaypalButton';
import donateImg1 from './work-600x338-50.jpg';
import donateImg2 from './heidelberg-600x338-50.jpg'
import donateImg3 from './berlin-01-600x338-50.jpg';
import donateImg4 from './hacking-600x338-50.jpg';


import styles from './donateFinalMesssage.module.css';

const DonateFinalPaypal = () => {
  const arrayImages = [donateImg1, donateImg2, donateImg3, donateImg4];
  const arrayAdText = [
    'Help keep this site online',
    'Help  this site keep improving',
  ];
  const rndmImgNum = Math.floor(Math.random() * 4);
  const rndmTxtNum = Math.floor(Math.random() * 2);
  const donateImge = arrayImages[rndmImgNum];
  const donateText = arrayAdText[rndmTxtNum];


  return (
    <div className={styles.wrap}>
      <div className={styles.donateImgWrap}>
        <img src={donateImge} alt="Jaymes Young" />
      </div>

      <p className={styles.donateP}>
        {donateText},
        <br />
        just $2 or $3 goes a long way</p>
      {/* <p className={styles.donateP}></p> */}

      <PaypalButton />
    </div>
  );
};

export default DonateFinalPaypal;
