import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// import SearchConjugation from './SearchConjugation';

import styles from './searchResults.module.css';

const SearchResultsSimple = ({ data, itemSearchType, lang }) => {
  const [englishData, setEnglishData] = useState();
  const [translationData, setTranslationData] = useState();

  // const [searchHeader, setSearchHeader] = useState();
  // const [searchRows, setSearchRows] = useState();


  // console.log(data);

  // const headMemoizedCallback = useCallback(() => {
  //   const keys = Object.keys(data[0]);

  //   const headers = keys.map((header) => {
  //     if (header === 'present' || header === 'imperfect') {
  //       return <th key={header}>{header}</th>;
  //     }

  //     return <th key={header} data-id={header} onClick={sortTableMemoizedCallback}>{header}</th>;
  //   });

  //   setSearchHeader(<tr>{headers}</tr>);
  // }, [data, sortTableMemoizedCallback]);

  // const rowsMemoizedCallback = useCallback(() => {
  //   const keys = Object.keys(data[0]);
  //   let newItemSearchType;

  //   if (itemSearchType === 'categories') {
  //     newItemSearchType = 'category';
  //   } else {
  //     newItemSearchType = itemSearchType.slice(0, itemSearchType.length - 1);
  //   }

  //   const rows = data.map((row) => {
  //     const cells = keys.map((cell) => {
  //       if (cell === 'id') {
  //         return (
  //           <td key="id" className={styles.searchId}>
  //             <Link to={`/admin/${newItemSearchType}/update/${row[cell]}`}>{row[cell]}</Link>
  //           </td>
  //         );
  //       }

  //       if (cell === 'present' || cell === 'imperfect') {
  //         return (
  //           <td key={cell} className={styles.searchResultsConjugation}>
  //             View
  //             <SearchConjugation data={row[cell]} />
  //           </td>
  //         );
  //       }

  //       if (cell === 'words' && Array.isArray(row[cell])) {
  //         return (
  //           <td key={cell}>{row[cell].join(', ')}</td>
  //         )
  //       }

  //       return (
  //         <td key={cell}>{row[cell]}</td>
  //       );
  //     });

  //     return (
  //       <tr key={row.id}>
  //         {cells}
  //       </tr>
  //     );
  //   });

  //   setSearchRows(rows);
  // }, [data, itemSearchType]);

  useEffect(() => {
    if (data[0] !== undefined) {
      console.log(data);

      const tempEnglishArray = [];
      const tempTranslationArray = [];

      data.forEach((obj) => {
        tempEnglishArray.push(<li key={obj.id}>{obj.english}</li>);
        tempTranslationArray.push(<li key={obj.id}>{obj.translation}</li>);
      });

      setEnglishData(tempEnglishArray);
      setTranslationData(tempTranslationArray);
    }
  }, [data]);

  return (
    <table className={styles.searchResultsSimple}>
      <tbody>
        <tr>
          <th>English</th>
          <th>Translation</th>
        </tr>
        <tr>
          <td><ul className={styles.ulEnglish}>{englishData}</ul></td>
          <td><ul className={styles.ulTranslation}>{translationData}</ul></td>
        </tr>
      </tbody>
    </table>
  );
};

SearchResultsSimple.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  itemSearchType: PropTypes.string,
  lang: PropTypes.string,
};

export default SearchResultsSimple;
