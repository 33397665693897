import React from 'react';
import PropTypes from 'prop-types';

import styles from './articles.module.css';

const ArticleButtons = ({ articles, handleArticleClick }) => (
  <div className={styles.articlesBox}>
    {articles !== undefined && (
      articles.map((article) => (
        <button key={article.id} className={styles.button} type="button" onClick={() => handleArticleClick(article.name)}>{article.name}</button>
      )))}
  </div>
);

ArticleButtons.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  handleArticleClick: PropTypes.func,
};

export default ArticleButtons;
