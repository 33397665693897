import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityCategoriesGrammar from '../ActivityComponents/ActivityCategoriesGrammar';
import ActivityCategoriesVocabulary from '../ActivityComponents/ActivityCategoriesVocabulary';
import ErrorCategory from '../../Loading/ErrorCategories';

const Cards = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  const phraseTopics = categories[lang].phrase;
  const phraseCategories = categories['all'].generalPhrase;

  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} flashcards - nouns, adjectives, verbs, phrases`,
      desc: `${languageName} flashcards. Improve your vocabulary by studying with flashcards. Adjectives, adverbs, nouns, verbs and ${languageName} phrases`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  // Get page content
  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {

    const tempTopicArray = [];

    categories[lang].phrase.forEach((topic) => {
      if (!tempTopicArray.includes(topic.category)) {
        tempTopicArray.push(topic.category);
      }
    });

    const newPhraseTopics = tempTopicArray.map((item) => {

      // Find category
      const categoryObj  = phraseCategories.find((catObj) => catObj.id === item);
      // filter topics
      const topicsArr = phraseTopics.filter((topicObj) => topicObj.category === item);
      const newTopicsArr = topicsArr.map((topicObj) => {
        return {'tId':topicObj.id , 'name':topicObj.name }
      });

      return { 'id':  item, 'name': categoryObj.name, 'topics': newTopicsArr};
    });

    pageContent = (
      <div className="activity-categories">
        {/* <h2>Adjectives</h2>
        <ActvityCategories
          activity="cards"
          lang={lang}
          category="adjective"
          categoryList={categories[lang].adjective}
        /> */}

        {/* <h2>Nouns</h2>
        {lang !== 'af' && (
          <ActvityCategories
            activity="cards"
            lang={lang}
            category="gender"
            categoryList={categories[lang].gender}
          />
        )} */}

        {/* <ActvityCategories
          activity="cards"
          lang={lang}
          category="noun"
          categoryList={categories[lang].noun}
        /> */}

        {/* <h2>Verbs</h2>
        <ActvityCategories
          activity="cards"
          lang={lang}
          category="verb"
        /> */}

        <div className="activity-categories">
          <ActivityCategoriesVocabulary
            activity="cards"
            lang={lang}
            categoryList={categories[lang].vocabTopics}
            fromPage="cardsIndex"
            locationHash={locationHash}
          />
        </div>

        <h2>Phrases</h2>

        <ActivityCategoriesGrammar
          activity="cards"
          pos="phrase"
          lang={lang}
          categoryList={newPhraseTopics}
        />
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Cards`}</h1>

        <p className="section-intro-text">German Flashcards to help you practice and remember your German vocabulary. All the words that are listed in the Flashcards here can also be found in the vocabulary lists.</p>

        {pageContent}
      </section>
    </MainLayout>
  );
}

export default Cards;
