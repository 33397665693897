import React from 'react';
import PropTypes from 'prop-types';

import styles from './articles.module.css';

const WordBox = ({ currentEnglish, selectedArticle, currentWord }) => (
  <div className={styles.wordBox}>
    <div>
      <p>{currentEnglish}</p>
      <span className={styles.wordBoxArticle}>{selectedArticle}</span>
      <span className={styles.wordBoxWord}>{currentWord}</span>
    </div>
  </div>
);

WordBox.propTypes = {
  currentEnglish: PropTypes.string,
  selectedArticle: PropTypes.string,
  currentWord: PropTypes.string,
};

export default WordBox;
