import React, {
  useState, useContext, useReducer, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { LearningContext } from '../Context';

import UpdateSelector from './UpdateSelector';
import TelegramTextarea from './FormComponents/TelegramTextarea';
// import FormSelect from './FormComponents/FormSelect';
import FormMessage from './FormComponents/FormMessage';
import withFormWrap from './withFormWrap';

import styles from './forms.module.css';

const Telegram = ({
  handleSubmit,
  categoryName,
  modifyType,
  updateId,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    id: '',
    telegramContent: 'This is how we do it.',
    telegramPost: true
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const { lang, labels } = useContext(LearningContext);
  const { en } = labels;
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [isButtonInsert, setIsButtonInsert] = useState(false);
  const [cursorPos, setCursorPos] = useState(0);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  const TOKEN = '5094824569:AAEK4J_n-HAZDN56I5V38t2n_lkqQ3C-9Pc';
  // const API_URL = `https://api.telegram.org/bot${TOKEN}/`;

  // 
  useEffect(() => {
    if (updateId !== undefined) {
      fetchUpdatedData(`https://deutscherphoenix.com/api/telegram/${updateId}?lang=${lang}`);
    }
  }, [fetchUpdatedData, updateId, lang]);

  //
  useEffect(() => {
    if (updateData.id !== undefined) {
      setFormState(updateData);
    }
  }, [updateData]);

  const clearForm = () => {
    setFormState({
      id: '',
    });
  };

  const memoizedClearForm = useCallback(clearForm, []);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  const handleIconClick = (e) => {
    const itemId = e.target.getAttribute('data-id');
    fetchUpdatedData(`https://deutscherphoenix.com/api/telegram/${itemId}?lang=${lang}`);
  };

  const handleChange = (content, type, positionCursor = '') => {
    // const { name, value } = e.target;
    setFormState({ 'telegramContent': content });
    setIsButtonInsert(type);
    setCursorPos(positionCursor)
  };

  const isValid = () => {
    if (formState.english === '') {
      return false;
    }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    // const msgText = `${formState.english} %0D%0A ${Omega} ${lb} ${emoji} ${fireEngine}`;
    const msgText = encodeURI(`${formState.telegramContent}`);

    // Group link
    fetchUrl = `https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=@DeutscherPhoenix01&parse_mode=html&text=${msgText}`;

    // if (modifyType === 'add') {
    //   fetchUrl = `https://deutscherphoenix.com/api/telegram?lang=${lang}`;
    // } else {
    //   fetchUrl = `https://deutscherphoenix.com/api/telegram/${formState.id}?lang=${lang}`;
    // }

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  const handleTestSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });   

    const msgText = encodeURI(`${formState.telegramContent}`);

    // Test link
    fetchUrl = `https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=@testChannelDp01&parse_mode=html&text=${msgText}`;
    
    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };




  const btnValue = `${modifyType.charAt(0).toUpperCase()}${modifyType.substring(1)} 
      ${categoryName.charAt(0).toUpperCase()}${categoryName.substring(1)}`;

  const langName = en.languages[lang];
  const heading = modifyType === 'update'
    ? `Update ${langName} Telegram`
    : `Add/Send ${langName} Telegram`;
  const gridClass = modifyType === 'update' ? styles.formLayoutGrid : '';
  const fetchUrl = `https://deutscherphoenix.com/api/telegram?lang=${lang}&cat=`;

  return (
    <div className={gridClass}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <h3 className={styles.header}>{heading}</h3>

        {/* <FormSelect
          name="topic"
          label="Topic"
          categories={categories.all.generalPhraseTopic}
          selected={formState.topic}
          handleCategory={handleChange}
        /> */}

        <TelegramTextarea
          label="Telegram Content"
          name="telegramContent"
          value={formState.telegramContent}
          handleChange={handleChange}
          isButtonInsert={isButtonInsert}
          cursorPos={cursorPos}
        />

        {/* <TelegramTextarea
          label="Translation"
          name="translation"
          value={formState.translation}
          handleChange={handleChange}
        /> */}

        <button className="form__button" type="button" onClick={handleTestSubmit}>Test Button</button>
        <br /><br />
        <button className="form__button" type="submit">{`${btnValue}`}</button>

        <br /><br />
        <a
          style={{'padding': '10px', 'color': 'yellow'}}
          href={`https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=@DeutscherPhoenix01&text=Guten Tag`}
        >
          Push Test to DeutscherPhoenix Channel
        </a>

        <br /><br />
        <a
          style={{'padding': '10px', 'color': 'yellow'}}
          href={`https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=@testChannelDp01&text=Guten Tag`}
        >
          Push Test to test channel
        </a>


        <FormMessage messageValues={messageValues} />
      </form>

      {modifyType === 'update' && (
        <UpdateSelector
          categoryType="phrase"
          handleIconClick={handleIconClick}
          fetchUrl={fetchUrl}
          propNameDisplay="translation"
          propNameToolTip="english"
        />
      )}
    </div>
  );
};

Telegram.propTypes = {
  handleSubmit: PropTypes.func,
  categoryName: PropTypes.string,
  modifyType: PropTypes.string,
  updateId: PropTypes.string,
  updateData: PropTypes.objectOf(PropTypes.string),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(Telegram);
