import React from 'react';
import PropTypes from 'prop-types';

const CardFaceBackVerbDe = ({
  cardData, handleFlipClick, handleExamplesToggle, exampleClass, exampleText, id,
}) => {
  let tenseLabel = '';
  let tblRows = '';

  console.log(id);

  if (id === 'perfect') {
    if (cardData.perfect) {
      tblRows = (
        <tr>
          <td className="card__table-col-one">{cardData.perfect.pronoun}</td>
          <td className="card__table-col-two">{cardData.perfect.value}</td>
        </tr>
      );
    }

    tenseLabel = 'Perfect';
  } else if (id === 'imperfect') {
    tenseLabel = 'Imperfect';
    tblRows = cardData.imperfect.map((obj) => {
      return (
        <tr key={obj.pronoun}>
          <td className="card__table-col-one">{obj.pronoun}</td>
          <td className="card__table-col-two">{obj.value}</td>
        </tr>
      )
    });
  } else {
    tenseLabel = 'Present';
    tblRows = cardData.present.map((obj) => {
      return (
        <tr key={obj.pronoun}>
          <td className="card__table-col-one">{obj.pronoun}</td>
          <td className="card__table-col-two">{obj.value}</td>
        </tr>
      )
    });
  }

  return (
    <div className="card__back--verb">
      <h3 className="card__heading">{cardData.translation}</h3>

      <table className="card__table">
        <thead>
          <tr>
            <th>Person</th>
            <th>{tenseLabel}</th>
          </tr>
        </thead>
        <tbody>
          {tblRows}
        </tbody>
      </table>

      <p className="card__example">{cardData.example}</p>

      <div className={`card__verb-examples ${exampleClass}`}>
        <h3 className="card__heading">{cardData.translation}</h3>
        <h4>Present</h4>
        <p className="card__example">{cardData.example}</p>
        <h4>Perfect</h4>
        <p className="card__example">{cardData.prf_example}</p>
        <h4>Imperfect</h4>
        <p className="card__example">{cardData.imp_example}</p>
      </div>

      <button
        type="button"
        className="card__txt-examples"
        onClick={handleExamplesToggle}
      >
        {exampleText}
      </button>
      <button
        type="button"
        className="card__txt-flip"
        onClick={handleFlipClick}
      >
        Flip
      </button>
    </div>
  );
};

CardFaceBackVerbDe.propTypes = {
  cardData: PropTypes.shape(),
  handleFlipClick: PropTypes.func,
  handleExamplesToggle: PropTypes.func,
  exampleClass: PropTypes.string,
  exampleText: PropTypes.string,
};

export default CardFaceBackVerbDe;
