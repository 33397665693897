import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorArticlesActivity from './ErrorArticlesActivity';
import ErrorArticlesFinal from './ErrorArticlesFinal';

import articlesData from './articlesData'

const ErrorArticlesGame = ({ lang }) => {
  const [componentType, setComponentType] = useState('activity');

  const changeComponent = (componentType) => {
    setComponentType(componentType);
  }

  if (componentType === 'final') {
    return (
      <ErrorArticlesFinal
        wordList={articlesData.data.words[lang]}
      />
    )
  }

  return (
    <ErrorArticlesActivity
      wordList={articlesData.data.words[lang]}
      changeComponent={changeComponent}
      lang="de"
    />
  );
};

ErrorArticlesGame.propTypes = {
  lang: PropTypes.string,
};

export default ErrorArticlesGame;
