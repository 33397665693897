import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import ActivityCategoriesGrammar from '../ActivityComponents/ActivityCategoriesGrammar';
import MainLayout from '../MainLayout';
import ErrorCategory from '../../Loading/ErrorCategories';

const Blanks = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} fill in the blank grammar activities`,
      desc: `${languageName} fill in the blank exercises. ${languageName} exercises covering grammar, verbs, nouns and adjectives. Each grammar exercise has a corresponding grammar lesson.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">
        <ActivityCategoriesGrammar
          activity="blanks"
          lang={lang}
          categoryList={categories[lang].blank}
          fromPage="blanksIndex"
          locationHash={locationHash}
        />
      </div>
    );
  }

  // Create function to swap out the word German for the other languages
  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Blanks`}</h1>

        <p className="section-intro-text">German fill in the blank exercises that cover important German grammar topics. Free exercises to practice grammar rules for beginner and intermediate learners. All exercise topics  can be studied in the grammar section.</p>

        {pageContent}

      </section>
    </MainLayout>
  );
};

export default Blanks;
