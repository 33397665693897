import React, { useState } from 'react';

import ArticlesActivity from './ArticlesActivity';
import ArticlesFinal from './ArticlesFinal';

import articlesData from './articlesData'

const Articles = () => {
  const [componentType, setComponentType] = useState('activity');

  const changeComponent = (componentType) => {
    setComponentType(componentType);
  }

  if (componentType === 'final') {
    return <ArticlesFinal
      wordList={articlesData.data.words['de']}
      lang="de"
    />;
  }

  return (
    <ArticlesActivity
      wordList={articlesData.data.words['de']}
      changeComponent={changeComponent}
      lang="de"
    />
  );
}

export default Articles;
