import React, { memo } from 'react';

import DonateFinalGoFundMe from './DonateFinalGoFundMe';
import DonateFinalPaypal from './DonateFinalPaypal';

const DonateFinal = () => {
  let donateComponent = <DonateFinalGoFundMe />;

  if (Math.floor(Math.random() * 2) === 1) {
    donateComponent = <DonateFinalPaypal />;
  }

  return (
    <>
      {donateComponent}
    </>
  );
}

export default memo(DonateFinal);