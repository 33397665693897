import React, {
  useState, useContext, useReducer, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { LearningContext } from '../Context';

// import { conjugatePresent } from './conjugateVerbs';

// Components
import UpdateSelector from './UpdateSelector';
import FormInput from './FormComponents/FormInput';
import FormSelect from './FormComponents/FormSelect';
import FormMessage from './FormComponents/FormMessage';
import VerbTenses from './VerbTenses';
import withFormWrap from './withFormWrap';
import Conjugate from './Conjugate';

import styles from './forms.module.css';

const Verb = ({
  handleSubmit,
  categoryName,
  modifyType,
  updateId,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    id: '',
    unit: '',
    category: '',
    english: '',
    infinitive: '',
    translation: '',
    type: 'weak',
    separable: 'no',
    reflexive: 'no',
    dative: 'no',
    ich: '',
    du: '',
    er: '',
    wir: '',
    ihr: '',
    sie: '',
    example: '',
    auxiliary: 'haben',
    perfect: '',
    prf_example: '',
    imp_ich: '',
    imp_du: '',
    imp_er: '',
    imp_wir: '',
    imp_ihr: '',
    imp_sie: '',
    imp_example: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const { categories, lang } = useContext(LearningContext);
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  // Get update data
  useEffect(() => {
    if (updateId !== undefined) {
      fetchUpdatedData(`https://deutscherphoenix.com/api/verbs/${updateId}?lang=${lang}`);
    }
  }, [fetchUpdatedData, updateId, lang]);

  // Set update data
  useEffect(() => {
    if (updateData.id !== undefined) {
      // const { present, imperfect } = updateData;
      // let objTempPresent = {};
      // let objTempImperfect = {};

      // present.forEach((obj) => {
      //   objTempPresent[obj.pronoun] = obj.value;
      // });

      // imperfect.forEach((obj) => {
      //   objTempImperfect[obj.pronoun] = obj.value;
      // });

      // console.log(objTempImperfect);

      setFormState({ ...updateData });
    }
  }, [updateData]);

  const clearForm = () => {
    if (modifyType === 'update') {
      setFormState({
        id: '',
        unit: '',
        category: '',
        english: '',
        infinitive: '',
        translation: '',
        type: 'weak',
        separable: 'no',
        reflexive: 'no',
        dative: 'no',
        ich: '',
        du: '',
        er: '',
        wir: '',
        ihr: '',
        sie: '',
        example: '',
        auxiliary: '',
        perfect: '',
        prf_example: '',
        imp_ich: '',
        imp_du: '',
        imp_er: '',
        imp_wir: '',
        imp_ihr: '',
        imp_sie: '',
        imp_example: '',
      });
    } else {
      setFormState({
        id: '',
        english: '',
        infinitive: '',
        translation: '',
        type: 'weak',
        separable: 'no',
        reflexive: 'no',
        dative: 'no',
        ich: '',
        du: '',
        er: '',
        wir: '',
        ihr: '',
        sie: '',
        example: '',
        auxiliary: '',
        perfect: '',
        prf_example: '',
        imp_ich: '',
        imp_du: '',
        imp_er: '',
        imp_wir: '',
        imp_ihr: '',
        imp_sie: '',
        imp_example: '',
      });
    }
  };

  const memoizedClearForm = useCallback(clearForm, [modifyType]);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  const handleIconClick = (e) => {
    const itemId = e.target.getAttribute('data-id');
    fetchUpdatedData(`https://deutscherphoenix.com/api/verbs/${itemId}?lang=${lang}`);
  };

  const handleConjugateClick = (conjugation) => {
    const { ich, du, er, wir, ihr, sie } = conjugation.present;
    const { perfect, auxiliary} = conjugation.perfect;

    setFormState({
      ich,
      du,
      er,
      wir,
      ihr,
      sie, 
      auxiliary,
      perfect,
      imp_ich: conjugation.imperfect.ich,
      imp_du: conjugation.imperfect.du,
      imp_er: conjugation.imperfect.er,
      imp_wir: conjugation.imperfect.wir,
      imp_ihr: conjugation.imperfect.ihr,
      imp_sie: conjugation.imperfect.sie,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  const handleTranslation = (e) => {
    setFormState({
      translation: e.target.value,
      infinitive: e.target.value
        .replace(/·/g, '')
        .replace(/\([\wÄÖÜäöü¨ß·-]*\)/i, '')
        .trim(),
    });
  };

  const handleChangeCheck = (e) => {
    const { name } = e.target;
    const newValue = formState[name] === 'yes' ? 'no' : 'yes';
    setFormState({ [name]: newValue });
  };

  const isValid = () => {
    if (
      formState.unit === ''
      || formState.category === ''
      || formState.english === ''
      || formState.infinitive === ''
      || formState.translation === ''
      || formState.type === ''
      || formState.ich === ''
      || formState.du === ''
      || formState.er === ''
      || formState.wir === ''
      || formState.ihr === ''
      || formState.sie === ''
      || formState.example === ''
      || formState.auxiliary === ''
      || formState.perfect === ''
      || formState.prf_example === ''
      || formState.imp_ich === ''
      || formState.imp_du === ''
      || formState.imp_er === ''
      || formState.imp_wir === ''
      || formState.imp_ihr === ''
      || formState.imp_sie === ''
      || formState.imp_example === ''
    ) {
      return false;
    }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    if (modifyType === 'add') {
      fetchUrl = `https://deutscherphoenix.com/api/verbs?lang=${lang}`;
    } else {
      fetchUrl = `https://deutscherphoenix.com/api/verbs/${formState.id}?lang=${lang}`;
    }

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  const btnValue = `${modifyType.charAt(0).toUpperCase()}${modifyType.substring(1)} 
      ${categoryName.charAt(0).toUpperCase()}${categoryName.substring(1)}`;

  const heading = modifyType === 'update' ? 'Update German Verbs' : 'Add German Verbs';
  const gridClass = modifyType === 'update' ? styles.formLayoutGrid : '';
  const fetchUrl = `https://deutscherphoenix.com/api/verbs?lang=${lang}&range=`;

  return (
    <div className={gridClass}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <h3 className={styles.header}>{heading}</h3>

        <div className="form__grid-2">
          <FormSelect
            name="unit"
            label="Unit"
            categories={categories.all.generalGrammar}
            selected={formState.unit}
            handleCategory={handleChange}
          />
          <FormSelect
            name="category"
            label="Category"
            categories={categories.all.general}
            selected={formState.category}
            handleCategory={handleChange}
          />
        </div>

        <label className="form__label--check" htmlFor="separable">
          <input
            id="separable"
            name="separable"
            className="form__check"
            type="checkbox"
            checked={formState.separable === 'yes'}
            onChange={handleChangeCheck}
          />
          Separable
        </label>

        <label className="form__label--check" htmlFor="reflexive">
          <input
            id="reflexive"
            name="reflexive"
            className="form__check"
            type="checkbox"
            checked={formState.reflexive === 'yes'}
            onChange={handleChangeCheck}
          />
          Reflexive
        </label>

        <label className="form__label--check" htmlFor="dative">
          <input
            id="dative"
            name="dative"
            className="form__check"
            type="checkbox"
            checked={formState.dative === 'yes'}
            onChange={handleChangeCheck}
          />
          Dative
        </label>

        <div>
          <label className="form__label--check" htmlFor="upVerbTypeMixed">
            <input
              id="upVerbTypeMixed"
              name="type"
              className="form__check"
              type="radio"
              value="mixed"
              checked={formState.type === 'mixed'}
              onChange={handleChange}
            />
            Mixed
          </label>

          <label className="form__label--check" htmlFor="upVerbTypeStrong">
            <input
              id="upVerbTypeStrong"
              name="type"
              className="form__check"
              type="radio"
              value="strong"
              checked={formState.type === 'strong'}
              onChange={handleChange}
            />
            Strong
          </label>

          <label className="form__label--check" htmlFor="upVerbTypeWeak">
            <input
              id="upVerbTypeWeak"
              name="type"
              className="form__check"
              type="radio"
              value="weak"
              checked={formState.type === 'weak'}
              onChange={handleChange}
            />
            Weak
          </label>
        </div>

        <FormInput
          label="English"
          name="english"
          value={formState.english}
          handleChange={handleChange}
        />

        <FormInput
          label={`Translation - ${formState.infinitive}`}
          name="translation"
          value={formState.translation}
          handleChange={handleTranslation}
        />

        {/* <button
          type="button"
          className="form__conjugate__button"
          onClick={handleConjugateClick}
        >
          Conjugate
        </button> */}

        <Conjugate
          infinitive={formState.infinitive}
          lang={lang}
          tense="present"
          handleConjugateClick={handleConjugateClick}
        />

        <VerbTenses
          lang={lang}
          tense="present"
          formState={formState}
          handleChange={handleChange}
        />

        <fieldset className={styles.fieldset}>
          <legend>Perfect</legend>

          <label
          className="form__label--check"
          htmlFor="upPerfectAuxiliaryHaben"
        >
          <input
            id="upPerfectAuxiliaryHaben"
            name="auxiliary"
            className="form__check"
            type="radio"
            value="haben"
            checked={formState.auxiliary === 'haben'}
            onChange={handleChange}
          />
          haben
        </label>

        <label
          className="form__label--check"
          htmlFor="upPerfectAuxiliarySein"
        >
          <input
            id="upPerfectAuxiliarySein"
            name="auxiliary"
            className="form__check"
            type="radio"
            value="sein"
            checked={formState.auxiliary === 'sein'}
            onChange={handleChange}
          />
          sein
        </label>

          <FormInput
            label="perfect"
            name="perfect"
            value={formState.perfect}
            handleChange={handleChange}
            pos="verb"
          />

          <FormInput
            label="perfect example"
            name="prf_example"
            value={formState.prf_example}
            handleChange={handleChange}
          />
        </fieldset>
        
        <VerbTenses
          lang={lang}
          tense="imperfect"
          formState={formState}
          handleChange={handleChange}
        />







        <button className="form__button" type="submit">{`${btnValue}`}</button>

        <FormMessage messageValues={messageValues} />
      </form>

      {modifyType === 'update' && (
        <UpdateSelector
          categoryType="range"
          handleIconClick={handleIconClick}
          fetchUrl={fetchUrl}
          propNameDisplay="translation"
          propNameToolTip="english"
        />
      )}
    </div>
  );
};

Verb.propTypes = {
  handleSubmit: PropTypes.func,
  categoryName: PropTypes.string,
  modifyType: PropTypes.string,
  updateId: PropTypes.string,
  updateData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(Verb);
