import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './footer.module.css';

import imgFacebook from './facebook.svg';
import imgInstgram from './instagram.svg';
import imgFullScreenEnter from './fullscreen-enter.svg';
import imgFullScreenExit from './fullscreen-exit.svg';


const Footer = () => {
  const [fullScreenImg, setFullScreenImg] = useState(imgFullScreenEnter);
  // const date = new Date().getFullYear();

  const toggleFullScreen = () => {
    const ele = document.getElementById('root');

    ele.requestFullscreen = ele.requestFullscreen || ele.mozRequestFullscreen
      || ele.msRequestFullscreen || ele.webkitRequestFullscreen;

    if (!document.fullscreenElement) {
      ele.requestFullscreen()
        .then(() => {
          setFullScreenImg(imgFullScreenExit);
        })
        .catch((err) => {
          console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
        .then(() => {
          setFullScreenImg(imgFullScreenEnter);
        })
        .catch((err) => {
          console.log(`Error exiting full-screen mode: ${err.message} (${err.name})`);
        });
    }
  };

  return (
    <footer className={styles.mainFooter}>
      <div className={`${styles.mainFooterWrap} contentWrap`}>
        {/* <small>{`\u00A9 ${date} Deutscher Phoenix`}</small> */}

        <div className={styles.socialWrap}>
          <a href="https://www.facebook.com/groups/607938506757397"><img src={imgFacebook} alt="facebook icon" title="Facebook" /></a>
          <a href="https://www.instagram.com/deutscherphoenix/"><img src={imgInstgram} alt="instagram icon" title="Instagram" /></a>
        </div>
        <div className={styles.privacyWrap}><Link to="/privacy" >Privacy</Link></div>
        <div className={styles.fullScreenWrap}>
          <button type="button" onClick={toggleFullScreen}><img src={fullScreenImg} alt="full screen icon" /></button>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
