import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormButton from './FormComponents/FormButton';

import styles from './conjugate.module.css';

const Conjugate = ({
  infinitive, lang, tense, handleConjugateClick,
}) => {
  const [progressState, setProgressState] = useState();
  const [progressText, setProgressText] = useState();

  const handleClick = () => {
    const failureInfinitive = {
      present: {
        ich: infinitive,
        du: infinitive,
        er: infinitive,
        wir: infinitive,
        ihr: infinitive,
        sie: infinitive,
      },
      perfect: {
        auxiliary: 'haben',
        perfect: infinitive,
      },
      imperfect: {
        ich: infinitive,
        du: infinitive,
        er: infinitive,
        wir: infinitive,
        ihr: infinitive,
        sie: infinitive,
      }
    };

    if (infinitive === '' || infinitive === undefined) {
      setProgressState(styles.error);
      setProgressText('Infinitive is missing!');
      return;
    }

    setProgressState(styles.loading);
    setProgressText('Loading ...');

    const fetchUrl = 'https://deutscherphoenix.com/api/conjugations';

    const formData = new FormData();
    formData.append('infinitive', infinitive);

    fetch(fetchUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
      },
      body: formData,
    })
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          setProgressState();
          setProgressText();
          handleConjugateClick(responseData.data);
        } else if (responseData.status === 'fail') {
          setProgressState(styles.error);
          setProgressText('Unable to conjugate!');
          handleConjugateClick(failureInfinitive);
        } else {
          setProgressState(styles.error);
          setProgressText('Unable to conjugate!');
          handleConjugateClick(failureInfinitive);
        }
      })
      .catch((error) => {
        setProgressState(styles.error);
        setProgressText('Unable to conjugate!');
        handleConjugateClick(failureInfinitive);
      });
  };

  return (
    <div className={styles.wrap}>
      <div>
        <FormButton
          type="button"
          label="Conjugate"
          handleClick={handleClick}
        />
      </div>
      <div>
        <span className={`${styles.progress} ${progressState}`}>{progressText}</span>
      </div>
    </div>
  );
};

Conjugate.propTypes = {
  infinitive: PropTypes.string,
  lang: PropTypes.string,
  tense: PropTypes.string,
  handleConjugateClick: PropTypes.func,
};

export default Conjugate;