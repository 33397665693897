import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LearningContext } from '../../Context';

import styles from './activityLink.module.css';

const ActivityLink = ({ toPath, text, id, fromPage, topicId, unitId, completed }) => {
  const {
    isUserLoggedIn,
  } = useContext(LearningContext);
  let location = toPath;
  let completedStyle = {};



  if (location.state !== undefined) {
    const { fromPage } = location.state;
    console.log('in activityLink, in if: ');
    if (fromPage === 'grammarList') {
      location = {
        pathname: toPath,
        state: {
          fromPage,
        }
      }
    } else if (fromPage === 'grammarInfo') {
      location = {
        pathname: toPath,
        state: {
          fromPage,
        }
      }
    }
  } else {
    // console.log('in activityLink, in else: ');
    location = {
      pathname: toPath,
      state: {
        fromPage,
        unitId,
        topicId
      }
    }
  }

  const arrText = text.split('-');

  if (completed === 'yes' && isUserLoggedIn === true) {
    completedStyle = {
      backgroundColor: '#7d47d6',
    }
  }

  if (arrText.length > 1) {
    const ucText1 = arrText[0].trim().charAt(0).toUpperCase() + arrText[0].slice(1).trim();
    const ucText2 = arrText[1].trim().charAt(0).toUpperCase() + arrText[1].trim().slice(1);

    return (
      <Link to={location} className={styles.button} id={id} style={completedStyle}>
        <span className={styles.spanWrap}>
          <span>
            {ucText1}
            <br />
            {ucText2}
          </span>
        </span>
      </Link>
    );
  }

  const ucText = arrText[0].trim().charAt(0).toUpperCase() + arrText[0].slice(1).trim();

  return (
    <Link to={location} className={styles.button} id={id} style={completedStyle}>
      <span className={styles.spanWrap}>
        <span>
          {ucText}
        </span>
      </span>
    </Link>
  );
};

ActivityLink.propTypes = {
  toPath: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string,
  topicId: PropTypes.string,
  unitId: PropTypes.string,
  completed: PropTypes.string,
  fromPage: PropTypes.string,
  activityType: PropTypes.string,
};

export default ActivityLink;
