import React from 'react';
import PropTypes from 'prop-types';

const ErrorArticlesHeading = ({ lang, hedingType }) => {
  if (hedingType === 'activity') {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>Unable to load resources</h2>
        <h3 style={{ textAlign: 'center' }}>Practice definite articles or close the page and try again.</h3>
      </>
    );
  }

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Here are your answers</h2>
      <h3 style={{ textAlign: 'center' }}>Please close the page and try again to load resources.</h3>
    </>
  )
};

ErrorArticlesHeading.propTypes = {
  lang: PropTypes.string,
  hedingType: PropTypes.string,
};

export default ErrorArticlesHeading;
