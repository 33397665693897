import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './formWrapResponse.module.css';

const FormWrapResponse = ({ actionResponse, setActionReponse }) => {
  const { status, message } = actionResponse;
  const [wrapShow, setWrapShow] = useState('');

  useEffect(() => {
    if (status !== '') {
      setWrapShow(styles.show);

      setTimeout(() => {
        setActionReponse({ status: '', message: '' });
      }, 4000);

    } else {
      setWrapShow('');
    }
  }, [status, setActionReponse]);

  return (
    <div className={`${styles.wrap} ${wrapShow}`}>
      <div>
        <button type="button" className={styles.closeButton} onClick={() => setActionReponse({ status: '', message: '' })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path className={styles.closeSvg} d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" /></svg>
        </button>
        <p>{message}</p>
      </div>
    </div>
  );
};

FormWrapResponse.propTypes = {
  actionResponse: PropTypes.objectOf(PropTypes.string),
  setActionReponse: PropTypes.func,
};

export default FormWrapResponse;
