import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityCategoriesGrammar from '../ActivityComponents/ActivityCategoriesGrammar';
import ComingSoon from '../ComingSoon';
import ErrorCategory from '../../Loading/ErrorCategories';

const Reading = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} stories, riddles, fables, fairy tales, weather`,
      desc: `${languageName} stores to help improve your ${languageName} vocabulary and reading skills. ${languageName} stories with English translations. Fairy tales, weather, riddles and news.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">
        <ActivityCategoriesGrammar
          activity="reading"
          lang={lang}
          category="reading"
          categoryList={categories[lang].story}
          fromPage="readingIndex"
          locationHash={locationHash}
        />
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Reading`}</h1>

        <p className="section-intro-text">German stories, news, weather, riddles, fables and more to help improve your German reading and comprehension. Reading will help you increase your vocabulary as well as help you become more familiar with German syntax.</p>

        {pageContent}

        {lang !== 'de' &&
          <ComingSoon message="Exercises Coming Soon!" />
        }

      </section>
    </MainLayout>
  );
}

export default Reading;