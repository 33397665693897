import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityCategoriesGrammar from '../ActivityComponents/ActivityCategoriesGrammar';
import ComingSoon from '../ComingSoon';
import ErrorCategory from '../../Loading/ErrorCategories';

const Grammar = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} grammar - syntax and structure | Deutscher Phoenix`,
      desc: `${languageName} grammar rules. Here you will learn the rules of the ${languageName} language. Noun cases, noun genders, verb conjugation, adjective declension and more.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  let grammarCategories;

  if (categories[lang] !== undefined && categories[lang].grammar.length > 0) {
    grammarCategories = (
      <ActivityCategoriesGrammar
        activity="grammar"
        lang={lang}
        categoryList={categories[lang].grammar}
        fromPage="grammarIndex"
        locationHash={locationHash}
      />
    );
  } else {
    grammarCategories = (
      <h2>
        Sorry, there no categories for this section.
        <br /><br />

        <ComingSoon message="Grammar Categories Coming Soon!" />
      </h2>
    );
  }

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">
        {grammarCategories}
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Grammar`}</h1>

        <p className="section-intro-text">German grammar lessons covering important subjects like verb conjugation, verb tenses, noun gender, cases, adjectives, propositions, word order, conjunctions, and more.  All in preparation for A1 and A2 testing.</p>

        {pageContent}
      </section>
    </MainLayout>
  );
};

export default Grammar;
