import React, { useEffect, useState } from 'react';

import Loading from '../Loading/LoadingActivity';
import Error from '../Loading/ErrorActivity';

import styles from './statistics.module.css';


const Statistics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [statisticData, setStatisticData] = useState(<p>Unable to get the current statistics</p>);
  let pageContent = '';

  const getStatistics = (data) => {
    const germanStats = data['de'].map((stat) => {
      return (
        <li key={stat.name}>{`${stat.name} - ${stat.count}`}</li>
      );
    });

    const dutchStats = data['nl'].map((stat) => {
      return (
        <li key={stat.name}>{`${stat.name} - ${stat.count}`}</li>
      );
    });

    const afrikaansStats = data['af'].map((stat) => {
      return (
        <li key={stat.name}>{`${stat.name} - ${stat.count}`}</li>
      );
    });

    return (
      <>
        <ul className={styles.ul}>{germanStats}</ul>
        <ul className={styles.ul}>{dutchStats}</ul>
        <ul className={styles.ul}>{afrikaansStats}</ul>
      </>
    )


  }

  // Get blanks
  useEffect(() => {
    fetch(
      'https://deutscherphoenix.com/api/statistics/',
    )
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          const data = responseData.data;

          setStatisticData(getStatistics(data));

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setFetchError(true);
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
        setIsLoading(false);
        setFetchError(true);
      });
  }, []);

  if (isLoading === true) {
    pageContent = <Loading />;
  } else if (fetchError === true) {
    pageContent = <Error />;
  } else {
    pageContent = (
      <div className={styles.wrap}>
        {statisticData}
      </div>
    )
  }






  return (
    <div>
      <h1>Statistics</h1>

      {pageContent}

    </div>
  );
}

export default Statistics;