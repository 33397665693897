import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './readingBox.module.css';

const ReadingBox = ({ category, englishText, translationText }) => {
  const [isTranslationVisible, setisTranslationVisible] = useState(false);
  const translationClass = isTranslationVisible ? styles.showBox : '';
  const btnText = isTranslationVisible ? 'Hide Translation' : 'Show Translation';
  const arrowDirectionClass = isTranslationVisible ? styles.arrowUp : styles.arrowDown;

  let tempEnglishArray = [];
  let tempTranslationArray = [];

  // after each click scroll to bottom?
  const toggleTranslation = () => setisTranslationVisible(!isTranslationVisible);

  // Maybe remove new lines and replace with <br />
  if (category === '3') {
    // The order of the patterns in the regular expression matters.
    tempEnglishArray = englishText.split(/\r\n|\r|\n/);
    tempTranslationArray = translationText.split(/\r\n|\r|\n/);
  } else {
    tempEnglishArray = [englishText];
    tempTranslationArray = [translationText];
  }

  // Convert to objects, so each line has an index.
  const englishArray = tempEnglishArray.map((item, i) => ({ id: i, text: item }));
  const translationArray = tempTranslationArray.map((item, i) => ({ id: i, text: item }));

  const translationContent = englishArray.map((obj) => <p key={obj.id}>{obj.text}</p>);
  const englishContent = translationArray.map((obj) => <p key={obj.id}>{obj.text}</p>);

  return (
    <div className={styles.wrap}>
      <div className={styles.textWrap}>
        {englishContent}
      </div>

      <button type="button" className={`${styles.button} ${arrowDirectionClass}`} onClick={() => toggleTranslation()}>
        {btnText}
      </button>
      <div className={`${styles.textWrapEn} ${translationClass}`}>
        {translationContent}
      </div>
    </div>
  );
}

ReadingBox.propTypes = {
  category: PropTypes.string,
  englishText: PropTypes.string,
  translationText: PropTypes.string,
}

export default ReadingBox;