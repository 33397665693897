import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import styles from './activityHeader.module.css';

const ActivityHeader = ({ currentItem, totalItems, type, lang, page, heading, handleCloseClick }) => {
  let location = useLocation();
  let newLocation = `/${page}/${lang}`;
  // console.log(location.state);
  if (location.state !== undefined && location.state !== null) {
    const { fromPage, topicId, unitId } = location.state;
    // console.log('in header, location state: ', location.state);

    // From page indexes list
    const fromPageIndexesListObject = {
      blanksIndex: 'blanks',
      readingIndex: 'reading',
      sentencesIndex: 'sentences',
      wordsIndex: 'words',
      grammarIndex: 'grammar',
      vocabularyIndex: 'vocabulary',
    };

    const fromPageIndexesListKeys = Object.keys(fromPageIndexesListObject);

    // From page Activities list
    const fromPageActivitiesListObject = {
      grammarActivity: 'grammar',
      vocabularyActivity: 'vocabulary',
    };

    const fromPageActivitiesListKeys = Object.keys(fromPageActivitiesListObject);


    if (fromPageIndexesListKeys.includes(fromPage)) {
      newLocation = {
        pathname: `/${fromPageIndexesListObject[fromPage]}/${lang}`,
        hash: unitId,
      }
    } else if (fromPageActivitiesListKeys.includes(fromPage)) {
      newLocation = {
        pathname: `/${fromPageActivitiesListObject[fromPage]}/${lang}/${topicId}`,
      }
    }
  }

  const percentCompleted = (currentItem / totalItems) * 100;

  const progress = {
    width: `${percentCompleted}%`,
  };

  let sidePadding = {};

  if (type !== 'progress') {
    sidePadding = {
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  }

  const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path className={styles.closeSvg} d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" /></svg>
  );

  if (type === 'progress') {
    return (
      <header>
        <div className={styles.headerWrap}>
          <div className={styles.progressBar}>
            <span className={styles.progress} style={progress} />
          </div>
          <Link to={newLocation} className={styles.closeLink}>
            {svgClose}
          </Link>
        </div>
      </header>
    );
  }

  if (type === 'toc') {
    return (
      <header style={sidePadding}>
        <div className={styles.headerWrap}>
          <h2 className={styles.heading}>Table of Contents</h2>
          <button type="button" className={styles.closeLink} onClick={handleCloseClick}>
            {svgClose}
          </button>
        </div>
      </header>
    );
  }

  return (
    <header style={sidePadding}>
      <div className={styles.headerWrap}>
        <h2 className={styles.heading}>{heading}</h2>
        <Link to={newLocation} className={styles.closeLink}>
          {svgClose}
        </Link>
      </div>
    </header>
  );
};

ActivityHeader.defaultProps = {
  type: 'text',
}

ActivityHeader.propTypes = {
  currentItem: PropTypes.number,
  totalItems: PropTypes.number,
  type: PropTypes.string,
  lang: PropTypes.string,
  page: PropTypes.string,
  heading: PropTypes.string,
  handleCloseClick: PropTypes.func,
};

export default ActivityHeader;
