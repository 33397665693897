import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityCategoriesVocabulary from '../ActivityComponents/ActivityCategoriesVocabulary';
import ComingSoon from '../ComingSoon';
import ErrorCategory from '../../Loading/ErrorCategories';

const Words = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet,
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} word match exercises - adjectives, nouns and verbs`,
      desc: `Improve your ${languageName} vocabulary with word match exercises. Match ${languageName} words with their English translations. ${languageName} adjectives, nouns and verbs`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">
        <ActivityCategoriesVocabulary
          activity="words"
          lang={lang}
          categoryList={categories[lang].wordTopics}
          fromPage="wordsIndex"
          locationHash={locationHash}
        />
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Words`}</h1>

        <p className="section-intro-text">German word match exercises. Match the German word with the English word. Match the words to build your German vocabulary. Nouns also show gender to help you remember.</p>

        {pageContent}
        <ComingSoon message="More Word Match Exercises Coming Soon!" />
      </section>
    </MainLayout>
  );
};

export default Words;
