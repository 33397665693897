import React, {
  useReducer, useContext
} from 'react';
import { useNavigate } from 'react-router-dom';
import { LearningContext } from '../Context';

import FormInput from './FormComponents/FormInput';

import styles from './forms.module.css';

const AdminLoginForm = () => {
  const { actions } = useContext(LearningContext);
  const initialFormState = {
    itemUser: '',
    itemPass: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const navigate = useNavigate();
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  // const isValid = () => {
  //   if (
  //     formState.itemUser === ''
  //     || formState.itemPass === ''
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const navigate = useNavigate();
    const formData = new FormData();
    formData.append('user', formState.itemUser);
    formData.append('password', formState.itemPass);

    fetch('https://deutscherphoenix.com/api/adminLogin.php', {
      method: 'POST',
      body: formData,
    })
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          actions.setAdminLogin(true);

          // Set session storage
          sessionStorage.setItem('isAdminLoggedIn', true);
          sessionStorage.setItem('jwt', responseData.data.jwt);
          const newPath = '/admin';
          navigate(newPath);
        } else {
          actions.setAdminLogin(false);
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
      });
  };

  return (
    <main>
      <div className={styles.loginWrap}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h3 className={styles.header}>Please Login</h3>

          <FormInput
            label="User"
            name="itemUser"
            value={formState.itemUser}
            handleChange={handleChange}
          />

          <FormInput
            label="Password"
            name="itemPass"
            value={formState.itemPass}
            handleChange={handleChange}
            type="password"
          />

          <button type="submit" className="form__button">
            Login
          </button>
        </form>
      </div>
    </main>
  );

}

// class AdminLoginForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       itemUser: '',
//       itemPass: '',
//     };
//   }

//   handleChange = (e) => {
//     const { name, value } = e.target;
//     this.setState({ [name]: value });
//   };

//   isValid = () => {
//     if (
//       this.user === ''
//       || this.item === ''
//       || this.itemPass === ''
//     ) {
//       return false;
//     }
//     return true;
//   };

//   handleSubmit = (e) => {
//     e.preventDefault();
//     const { actions } = this.context;
//     const { itemUser, itemPass } = this.state;
//     const navigate = useNavigate();
//     const formData = new FormData();
//     formData.append('user', itemUser);
//     formData.append('password', itemPass);

//     fetch('https://deutscherphoenix.com/api/adminLogin.php', {
//       method: 'POST',
//       body: formData,
//     })
//       .then((reponse) => reponse.json())
//       .then((responseData) => {
//         if (responseData.status === 'success') {
//           actions.setAdminLogin(true);

//           // Set session storage
//           sessionStorage.setItem('isAdminLoggedIn', true);
//           sessionStorage.setItem('jwt', responseData.data.jwt);
//           const newPath = '/admin';
//           navigate(newPath);
//         } else {
//           actions.setAdminLogin(false);
//         }
//       })
//       .catch((error) => {
//         console.log('Error fetching and parsing data', error);
//       });
//   };

//   render() {
//     const { itemUser, itemPass } = this.state;
//     return (
//       <main>
//         <div className={styles.loginWrap}>
//           <form className={styles.form} onSubmit={handleSubmit}>
//             <h3 className={styles.header}>Please Login</h3>

//             <FormInput
//               label="User"
//               name="itemUser"
//               value={itemUser}
//               handleChange={this.handleChange}
//             />

//             <FormInput
//               label="Password"
//               name="itemPass"
//               value={itemPass}
//               handleChange={this.handleChange}
//               type="password"
//             />

//             <button type="submit" className="form__button">
//               Login
//             </button>
//           </form>
//         </div>
//       </main>
//     );
//   }
// }

AdminLoginForm.contextType = LearningContext;

export default AdminLoginForm;
