import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LearningContext } from '../Context';

import HomeImage from './Home/HomeImage';

import styles from './Home/index.module.css';

const NotFound = () => {
  const { lang, labels } = useContext(LearningContext);

  const blanksLabel = labels['en'].activities.blanks;
  const cardsLabel = labels['en'].activities.cards;
  const grammarLabel = labels['en'].activities.grammar;
  const readingLabel = labels['en'].activities.reading;
  const sentencesLabel = labels['en'].activities.sentences;
  const wordsLabel = labels['en'].activities.words;

  const languageNames = {
    de: 'German',
    nl: 'Dutch',
    af: 'Afrikaans'
  };

  return (
    <main className={styles.outerWrap}>
      <section className={styles.innerWrap}>
        <HomeImage />

        <h2 className={styles.notFoundHeading}>404 Nicht Gefunden / Not Found</h2>
        <p className={styles.notFoundMessage}>
          Es tut uns leid
          <br />
          Wir können Ihre Seite nicht finden
          <br />
          -
          <br />
          We're sorry
          <br />
          We cannot find the page you are searching for.
        </p>

        <h2>Activities</h2>
        <ul className={`${styles.notFoundUl}`}>
          <li className={styles.subMenuItem}><Link to={`/blanks/${lang}`}>{blanksLabel} - Fill in the blank exercises</Link></li>
          <li className={styles.subMenuItem}><Link to={`/cards/${lang}`}>{cardsLabel} - Flash cards</Link></li>
          <li className={styles.subMenuItem}><Link to={`/reading/${lang}`}>{readingLabel} - Reading Exercises</Link></li>
          <li className={styles.subMenuItem}><Link to={`/sentences/${lang}`}>{sentencesLabel} - Sentence creation exercises</Link></li>
          <li className={styles.subMenuItem}><Link to={`/words/${lang}`}>{wordsLabel} - Word match exercises</Link></li>
        </ul>

        <h2>Study</h2>
        <ul className={`${styles.notFoundUl}`}>
          <li className={styles.subMenuItem}><Link to={`/grammar/${lang}`}>{`${languageNames[lang]} ${grammarLabel}`}</Link></li>
          <li className={styles.subMenuItem}><Link to={`/vocabulary/${lang}`}>{`${languageNames[lang]} Vocabulary`}</Link></li>
        </ul>

      </section>
    </main>
  )
};

export default NotFound;
