import React from 'react';

import styles from './donateButton.module.css';
// https://www.gofundme.com/f/help-jaymes-realize-his-german-fairytale-dream/donate

import svgGofundme from './gofundme.svg';

const GofundmeButton = () => (
  <div className={styles.donateBtn}>
    <a
      className={styles.donateBtnGrid}
      href="https://www.gofundme.com/f/help-jaymes-realize-his-german-fairytale-dream/"
      target="_blank"
      rel='noreferrer'
    >
      <img className={styles.donateIcon} src={svgGofundme} alt="go fund me icon" />
      <span className={styles.donateText}>DONATE NOW</span>
    </a>
  </div>

);

export default GofundmeButton;
