import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActivityLink from './ActivityLink';
import TableOfContents from '../TableOfContents';

import styles from './activityCategories.module.css';

const ActvityCategoriesVocabulary = ({
  activity, lang, categoryList, fromPage, locationHash
}) => {
  const [isTableOfContentsShown, setIsTableOfContentsShown] = useState(false);
  const tocCategoryArray = [];

  const handleButtonClick = () => setIsTableOfContentsShown(true);

  const handleTocClose = (isShown) => setIsTableOfContentsShown(isShown);

  const objRefs01 = categoryList.adj.reduce((acc, item) => {
    acc[item.id] = createRef();
    return acc;
  }, {});

  const objRefs02 = categoryList.noun.reduce((acc, item) => {
    acc[item.id] = createRef();
    return acc;
  }, { ...objRefs01 });

  const objRefs = categoryList.verb.reduce((acc, item) => {
    acc[item.id] = createRef();
    return acc;
  }, { ...objRefs02 });

  // Scroll to section if locationHash is not undefined
  useEffect(() => {
    if (locationHash !== undefined) {
      let hashSectionId = locationHash.replace('#', '');
      objRefs[hashSectionId].current.scrollIntoView({ behavior: 'auto' });
    }
  }, [locationHash, objRefs]);

  let unitIds = [];
  let unitTopics = [];

  // Noun ids
  categoryList.noun.forEach((unit) => {
    unitIds.push(unit.id);
  });
  // Adj ids that aren't duplicate noun ids
  categoryList.adj.forEach((unit) => {
    if (unitIds.indexOf(unit.id) === -1) {
      unitIds.push(unit.id);
    }
  });
  // Verb ids that aren't duplicate noun or adj ids
  categoryList.verb.forEach((unit) => {
    if (unitIds.indexOf(unit.id) === -1) {
      unitIds.push(unit.id);
    }
  });

  // Sort ids by number
  unitIds.sort((a, b) => a - b);

  // Loop through ids and create units and topics
  unitIds.forEach((item) => {
    const tempObj = {
      id: item,
      name: `Unit ${item}`,
    };

    // Noun
    if (categoryList.noun.find((noun) => noun.id === item)) {
      const nounObj = categoryList.noun.find((noun) => noun.id === item);
      tempObj['nounTopics'] = nounObj.topics;
    }

    // Adj
    if (categoryList.adj.find((adj) => adj.id === item)) {
      const adjObj = categoryList.adj.find((adj) => adj.id === item);
      tempObj['adjTopics'] = adjObj.topics;
    }

    // Verb
    if (categoryList.verb.find((verb) => verb.id === item)) {
      const verbObj = categoryList.verb.find((verb) => verb.id === item);
      tempObj['verbTopics'] = verbObj.topics;
    }

    unitTopics.push(tempObj);
  });

  const getTopicLinks = (unitId, topicsArray, pos) => {
    const topics = topicsArray.map((topic) => {
      let toPath = `/${activity}/${lang}/${pos}/${unitId}/${topic.tId}`;

      return (
        <ActivityLink
          key={topic.tId}
          toPath={toPath}
          text={topic.name}
          id={`${topic.tId}`}
          fromPage={fromPage}
          unitId={unitId}
        />
      )
    });

    return topics;
  }

  const sections = unitTopics.map((unit) => {
    let topicsObject = [];
    let finalTopics = '';
    const tocUnitObject = { id: unit.id, name: `Unit ${unit.id}` };

    if (unit.nounTopics) {
      topicsObject.push({ id: 1, headingName: 'Nouns', topicLinks: getTopicLinks(unit.id, unit.nounTopics, 'noun') });
      tocUnitObject['noun'] = unit.nounTopics;
    }

    if (unit.adjTopics) {
      topicsObject.push({ id: 2, headingName: 'Adjectives', topicLinks: getTopicLinks(unit.id, unit.adjTopics, 'adjective') });
      tocUnitObject['adjective'] = unit.adjTopics;
    }

    if (unit.verbTopics) {
      topicsObject.push({ id: 3, headingName: 'Verbs', topicLinks: getTopicLinks(unit.id, unit.verbTopics, 'verb') });
      tocUnitObject['verb'] = unit.verbTopics;
    }

    // Units and topics for table of contents
    tocCategoryArray.push(tocUnitObject);

    const tempTopics = topicsObject.map((obj) => {
      return (
        <React.Fragment key={obj.id}>
          <h3>{obj.headingName}</h3>
          <div className={styles.btnWrap}>
            {obj.topicLinks}
          </div>
        </React.Fragment>
      );
    });

    finalTopics = (
      <div>
        {tempTopics}
      </div>
    );

    return (
      <div
        key={unit.id}
        ref={objRefs[unit.id]}
        className={styles.wrap}
      >
        <h2 className="h2Unit"><span>{unit.name}</span></h2>

        {finalTopics}
      </div>
    );
  });

  return (
    <div>
      <button
        className={styles.btnToc}
        type="button"
        onClick={() => handleButtonClick()}
      >
        Table of Contents
      </button>

      {isTableOfContentsShown === true && (
        <TableOfContents
          handleTocClose={handleTocClose}
          categoryList={tocCategoryArray}
          fromPage={fromPage}
          activity={activity}
          lang={lang}
        />
      )}
      {sections}
    </div>
  );
};

ActvityCategoriesVocabulary.defaultProps = {
  categoryList: '',
};

ActvityCategoriesVocabulary.propTypes = {
  activity: PropTypes.string,
  lang: PropTypes.string,
  categoryList: PropTypes.object,
  locationHash: PropTypes.string,
};

export default ActvityCategoriesVocabulary;
