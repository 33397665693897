import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';
import { useLocation } from 'react-router-dom';

import MainLayout from '../MainLayout';
import ActivityCategoriesVocabulary from '../ActivityComponents/ActivityCategoriesVocabulary';
import ComingSoon from '../ComingSoon';
import ErrorCategory from '../../Loading/ErrorCategories';

const Vocabulary = () => {
  const {
    categories, lang, languageName, labels, actions, areCategoriesSet
  } = useContext(LearningContext);
  const location = useLocation();
  const headingLabel = labels.en.languages[lang];
  let pageContent = '';
  let locationHash = undefined;

  if (location.hash !== '') {
    locationHash = location.hash.replace('#', '');
  }

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;

    const headObj = {
      title: `${languageName} vocabulary - adjectives, adverbs, nouns, verbs`,
      desc: `${languageName} vocabulary. Improve your reading and understanding by increasing your ${languageName} vocabulary. ${languageName} nouns, adjectives, verbs, adverbs and prepositions.`,
    };
    setDocumentHead(headObj);
  }, [actions, languageName]);

  if (areCategoriesSet === false) {
    pageContent = <ErrorCategory />
  } else {
    pageContent = (
      <div className="activity-categories">
        <ActivityCategoriesVocabulary
          activity="vocabulary"
          lang={lang}
          categoryList={categories[lang].vocabTopics}
          fromPage="vocabularyIndex"
          locationHash={locationHash}
        />
      </div>
    );
  }

  return (
    <MainLayout>
      <section>
        <h1>{`${headingLabel} Vocabulary`}</h1>

        <p className="section-intro-text">German vocabulary lists. The German words you will need to get started with the German language. Words to help you start talking about who you are and where you're from. Plus vocabulary for many other topics, including people, school, animals, food, clothing, family, home and much more.</p>

        {pageContent}
        <ComingSoon message="More Vocabulary Coming Soon!" />
      </section>
    </MainLayout>
  );
};

export default Vocabulary;
