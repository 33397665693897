import React from 'react';
import PropTypes from 'prop-types';

import styles from './comingSoon.module.css';

const ComingSoon = ({ message }) => {
  return (<div className={styles.wrap}>
    <h3 className={styles.h3}>{message}</h3>
  </div>);
}

ComingSoon.propTypes = {
  message: PropTypes.string,
}

export default ComingSoon;