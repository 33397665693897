import React, { useContext } from 'react';
import {
  Routes,
  Route,
  Navigate,
  Link,
} from 'react-router-dom';

import { LearningContext } from '../Context';

import AdminContent from './AdminContent';
import Search from './Search';
import Statistics from './Statistics';

import styles from './index.module.css';

import '../../css/admin.css';
import '../../css/forms.css';

import svgAdd from './add-outline.svg';
import svgUpdate from './edit-pencil.svg';
import svgDelete from './trash.svg';
import svgSearch from './Search/search.svg';
import svgRefresh from './refresh.svg';

const Admin = () => {
  const { isAdminLoggedIn, actions } = useContext(LearningContext);

  const getListItem = (category, heading) => {
    const functions = [
      { type: 'add', img: svgAdd },
      { type: 'update', img: svgUpdate },
      { type: 'delete', img: svgDelete },
    ];

    const links = functions.map((func) => {
      const funcName = func.type.charAt(0).toUpperCase() + func.type.slice(1);
      return (
        <Link key={func.type} to={`/admin/${category}/${func.type}`}>
          <img src={func.img} alt={funcName} />
        </Link>
      );
    });

    return (
      <li className={styles.optionsMenuItem}>
        <h3>{heading}</h3>
        {links}
      </li>
    );
  };

  return (
    <main className={styles.outerWrap}>
      <section className={styles.admin}>
        <nav className={styles.nav}>
          <h3 className={styles.h3}>Admin</h3>
          <ul className={styles.optionsMenu}>
            <li className={styles.optionsMenuItem}>
              <h3>Tools</h3>
              <span
                className="faux-btn"
                role="button"
                onClick={() => actions.getCategories()}
                onKeyPress={() => actions.getCategories()}
                tabIndex="0"
              >
                <img src={svgRefresh} alt="Refresh Categories" />
              </span>
              <Link to="/admin/search">
                <img src={svgSearch} alt="search" />
              </Link>
            </li>

            <li className={styles.optionsMenuItem}>
              <h3>Statistics</h3>
              <Link to="/admin/statistics">
                <img src={svgUpdate} alt="update icon" />
              </Link>
            </li>

            {getListItem('telegram', 'Telegram')}
            {getListItem('adjective', 'Adjectives')}
            {getListItem('blank', 'Blanks')}
            {getListItem('category', 'Categories')}
            {getListItem('grammar', 'Grammar')}
            {getListItem('noun', 'Nouns')}
            {getListItem('phrase', 'Phrases')}
            {getListItem('sentence', 'Sentences')}
            {getListItem('story', 'Stories')}
            {getListItem('verb', 'Verbs')}

            <li className={styles.optionsMenuItem}>
              <h3>
                Verbs
                <span>(perfect)</span>
              </h3>
              <Link to="/admin/perfect/update">
                <img src={svgUpdate} alt="update icon" />
              </Link>
            </li>

            <li className={styles.optionsMenuItem}>
              <h3>
                Verbs
                <span>(imperfect)</span>
              </h3>
              <Link to="/admin/imperfect/update">
                <img src={svgUpdate} alt="update icon" />
              </Link>
            </li>

            <hr />

            <li className={styles.optionsMenuItem}><Link to="/" onClick={() => actions.setAdminLogin(false)}>Logout</Link></li>
          </ul>
        </nav>

        <div style={{ paddingTop: '20px' }}>
          <Routes>
            <Route
              path="/search"
              element={!isAdminLoggedIn ? <Navigate to="/" /> : <Search /> }
            />
            <Route
              path="/statistics"
              element={!isAdminLoggedIn ? <Navigate to="/" /> : <Statistics />}
            />
            <Route
              path="/"
              element={!isAdminLoggedIn ? <Navigate to="/" /> : <Statistics />}
            />
            <Route
              path="/:category/:modifyType/:updateId?"
              element={!isAdminLoggedIn ? <Navigate to="/" /> : <AdminContent />}
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default Admin;
