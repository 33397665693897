import React, { useContext, useEffect } from 'react';
import { LearningContext } from '../../Context';

import HomeImage from './HomeImage';
import HomeRandomItems from './HomeRandomItems';

import styles from './index.module.css';

const Home = () => {
  const { languageName, categories, actions } = useContext(LearningContext);

  // Set document head information
  useEffect(() => {
    const { setDocumentHead } = actions;
    const headObj = {
      title: `Deutscher Phoenix | ${languageName} Language Study`,
      desc: `Language tools for beginning ${languageName} learners. Grammar, flashcards, exercises, stories and vocabulary. 100% free`,
    };

    if (document.title !== 'Deutscher Phoenix | German Language Study') {
      setDocumentHead(headObj);
    }
  }, [actions, languageName]);

  return (
    <main className={styles.outerWrap}>
      <section className={styles.innerWrap}>
        <HomeImage />
        {/* Hi there! Though I provide all blog content for free, your support will be very much appreciated. */}

        <p className={styles.description}>1,000s of German exercises to practice and improve you german language skills. Fill in the blanks, create sentences, word matches, and flashcards.</p>

        <div className={styles.donateWrap}>
          <p className={styles.message}><b>Help keep this site growing and improving
            <br />
            Just $2 or $3 goes a long way<br />
            Your support is much appreciated</b></p>
          <form action="https://www.paypal.com/donate" method="post" target="_blank">
            <input type="hidden" name="business" value="CRVCJLXBGXVVE" />
            <input type="hidden" name="item_name" value="Intensive German Language Study in Germany" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>

        {
          categories.randomItems &&
          <HomeRandomItems />
        }
      </section>
    </main>
  )
};

export default Home;
