import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ActivityHeader from '../ActivityComponents/ActivityHeader';

import styles from './tableOfContents.module.css';

const TableOfContents = ({
  handleTocClose, categoryList, activity, fromPage, lang, 
}) => {
  // const [unitNumber, setUnitNumber] = useState(0);
  // const [unitGrammarContents, setUnitGrammarContents] = useState();
  // const [unitNounContents, setUnitNounContents] = useState();
  // const [unitAdjectiveContents, setUnitAdjectiveContents] = useState();
  // const [unitVerbContents, setUnitVerbContents] = useState();
  const vocabularyActivitiesArray = ['cards', 'vocabulary', 'words'];

  const handleCloseClick = () => {
    handleTocClose(false);
  }

  // const showGrammarUnitContents = (unitNumber) => {
  //   const filteredUnitContents = categoryList.filter((obj) => obj.id === unitNumber);

  //   if (filteredUnitContents.length === 0) {
  //     return;
  //   }

  //   const tempUnitContents = filteredUnitContents[0].topics.map((obj) => {
  //     let toPath = `/${activity}/${lang}/${obj.tId}`;

  //     const location = {
  //       pathname: toPath,
  //       state: {
  //         fromPage,
  //         unitId: unitNumber,
  //         topicId: obj.tId,
  //       }
  //     }

  //     return (
  //       <li key={obj.tId}>
  //         <Link to={location}>
  //           {obj.name}
  //         </Link>
  //       </li>
  //     );
  //   });

  //   setUnitGrammarContents(tempUnitContents);
  // }



  // const showVocabularyUnitContents = (unitNumber) => {
  //   const filteredUnitContents = categoryList.filter((obj) => obj.id === unitNumber);

  //   if (filteredUnitContents.length === 0) {
  //     return;
  //   }

  //   const getUnitLis = (posArray, pos, unit) => {
  //     const tempUnitPosContents = posArray.map((obj) => {
  //       const toPath = `/${activity}/${lang}/${pos}/${unit}/${obj.tId}`;

  //       const location = {
  //         pathname: toPath,
  //         state: {
  //           fromPage,
  //           unitId: unitNumber,
  //           topicId: obj.tId,
  //         }
  //       }

  //       return (
  //         <li key={obj.tId}>
  //           <Link to={location}>
  //             {obj.name}
  //           </Link>
  //         </li>
  //       );
  //     });

  //     return tempUnitPosContents;
  //   }

  //   if (filteredUnitContents[0].noun !== undefined) {
  //     setUnitNounContents(getUnitLis(filteredUnitContents[0].noun, 'noun', unitNumber));
  //   }

  //   if (filteredUnitContents[0].adjective !== undefined) {
  //     setUnitAdjectiveContents(getUnitLis(filteredUnitContents[0].adjective, 'adjective', unitNumber));
  //   }

  //   if (filteredUnitContents[0].verb !== undefined) {
  //     setUnitVerbContents(getUnitLis(filteredUnitContents[0].verb, 'verb', unitNumber));
  //   }
  // }

  // const handleOnChange = (e) => {
  //   if (e.target.value === '0') {
  //     setUnitNumber(e.target.value);
  //     return;
  //   }

  //   setUnitNumber(e.target.value);

  //   if (vocabularyActivitiesArray.includes(activity)) {
  //     showVocabularyUnitContents(e.target.value);
  //   } else {
  //     showGrammarUnitContents(e.target.value);
  //   }
  // }

  // const tocOptions = categoryList.map((obj) => <option key={obj.id} value={obj.id}>{obj.name}</option>);


  // Vocabulary TOC
  const getVocabularyToc = () => {
    const tocVocabulary = categoryList.map((unit) => {
      let adjectives = '';
      let nouns = '';
      let verbs = '';
  
      const getLi = (pos, posObj) => {
        const toPath = `/${activity}/${lang}/${pos}/${unit.id}/${posObj.tId}`;
  
          const location = {
            pathname: toPath,
            state: {
              fromPage,
              unitId: unit.id,
              topicId: posObj.tId,
            }
          }
  
          return (
            <li key={posObj.tId}>
              <Link to={location}>
                {posObj.name}
              </Link>
            </li>
          );
      }
  
      if (unit.adjective !== undefined) {
        adjectives = unit.adjective.map((posObj) => {
          return getLi('adjective', posObj);
        });
      }
  
      if (unit.noun !== undefined) {
        nouns = unit.noun.map((posObj) => {
          return getLi('noun', posObj);
        });
      }
  
      if (unit.verb !== undefined) {
        verbs = unit.verb.map((posObj) => {
          return getLi('noun', posObj);
        });
      }
  
      return (
        <div key={unit.id}>
          <h3>{unit.name}</h3>
  
          {adjectives !== '' &&
            <>
              <h4>Adjectives</h4>
              <ul className={styles.ul}>{adjectives}</ul>
            </>
          }
  
          {nouns !== '' &&
            <>
              <h4>Nouns</h4>
              <ul className={styles.ul}>{nouns}</ul>
            </>
          }
  
          {verbs !== '' &&
            <>
              <h4>Verbs</h4>
              <ul className={styles.ul}>{verbs}</ul>
            </>
          }
        </div>
      );
    });

    return tocVocabulary;
  };

  // Grammar TOC
  const getGrammrToc = () => {
    const tocGrammar = categoryList.map((unit) => {
      const topics = unit.topics.map((topic) => {
        let toPath = `/${activity}/${lang}/${topic.tId}`;
    
        const location = {
          pathname: toPath,
          state: {
            fromPage,
            unitId: unit.id,
            topicId: topic.tId,
          }
        };
  
        return (
          <li key={topic.tId}>
            <Link to={location}>
              {topic.name}
            </Link>
          </li>
        );
      });
  
      return (
        <div key={unit.id}>
          <h3>{unit.name}</h3>
          <ul className={styles.ul}>{topics}</ul>
        </div>
      );
    });

    return tocGrammar;
  }

  const tocDivs = vocabularyActivitiesArray.includes(activity) ? getVocabularyToc(): getGrammrToc();

  return (
    <div className={styles.wrap}>

      <ActivityHeader
        type="toc"
        handleCloseClick={handleCloseClick}
      />

      <div className={styles.innerWrap}>        
        {tocDivs}
      </div>
    </div>
  );
};

TableOfContents.propTypes = {
  handleTocClose: PropTypes.func,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  activity: PropTypes.string,
  lang: PropTypes.string,
  fromPage: PropTypes.string,
};

export default TableOfContents;
